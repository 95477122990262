import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, FormControl, Grid, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel, FormControlLabel } from './CampaignWizardStyles';
import moment from 'moment-timezone';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDCurrencyField } from '../../../../components/CDCurrencyField';
import { CDTextField } from '../../../../components/CDTextField';
import { UserContext } from '../../../../App';
import { useCampaignAudience } from '../../../../hooks/useCampaignAudience';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CampaignDuplicateLoader from '../CampaignDuplicateLoader';
import CDButton from '../../../../components/CDButton';
import CDDatePicker from '../../../../components/CDDatePicker';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';
import Utils from '../../../../components/Utils';

type CampaignSetupDeliveryCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupDeliveryCard(props: CampaignSetupDeliveryCardProps) {
    const { campaign, onChange } = props;
    const { userContext } = useContext(UserContext);
    const { hasError, isCompletedCampaign, isDraftCampaign, isNewCampaign } = useCampaignWizardContext();

    const $campaignAudience = useCampaignAudience({
        campaign: campaign,
        onChange: (budget: number, impressionGoal: number | null, impressionBased: boolean): void => {
            if (isNewCampaign || isDraftCampaign) {
                onChange({
                    budget: budget,
                    impressionGoal: impressionGoal,
                    impressionBased,
                });
            } else {
                onChange({
                    impressionGoal: impressionGoal,
                    impressionBased,
                });
            }
        },
    });
    const { impressionBased, setImpressionBased } = $campaignAudience;
    const { handleChangeImpression } = $campaignAudience;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            onChange({
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleDateChange = (dateKey: string, dateValue: Date | null) => {
        if (dateValue !== null) {
            if (dateKey === 'startDate') {
                if (moment(campaign.endDate).diff(moment(dateValue)) < 0) {
                    onChange({
                        endDate: null,
                    });
                }
            }

            onChange({
                [dateKey]: dateValue,
            });
        }
    };

    const campaignRef = useRef(campaign);
    campaignRef.current = campaign;

    const startDateMinDate = useMemo(() => {
        return moment().subtract(1, 'days').toDate();
    }, []);

    const [campaignBudget, setCampaignBudget] = useState<string>('');
    const [isChangingCampaignBudget, setIsChangingCampaignBudget] = useState<boolean>(false);
    const [debouncedCampaignBudget, setDebouncedCampaignBudget] = useState<string>(campaignBudget);
    const debouncedCampaignBudgetTimeout = useRef<any | null>(null);

    const handleChangeBudget = (event: ChangeEvent<HTMLInputElement>) => {
        setCampaignBudget(parseFloat(event.target.value) > 5 ? event.target.value : '5');
    };

    useEffect(() => {
        setIsChangingCampaignBudget(true);

        debouncedCampaignBudgetTimeout.current = setTimeout(() => {
            setDebouncedCampaignBudget(campaignBudget);
            setIsChangingCampaignBudget(false);
        }, 2000);

        return () => {
            clearTimeout(debouncedCampaignBudgetTimeout.current);
        };
    }, [campaignBudget]);

    useEffect(() => {
        if (debouncedCampaignBudget) {
            onChange({
                budget: parseFloat(debouncedCampaignBudget),
            });
        }
    }, [debouncedCampaignBudget, onChange]);

    useEffect(() => {
        setCampaignBudget(campaign.budget ? (campaign.budget as any as string) : '');
    }, [campaign.budget]);

    const isStartDateDisabled = useMemo(() => {
        if (!campaign?.id) {
            return false;
        }

        return isDraftCampaign === false;
    }, [campaign, isDraftCampaign]);

    const handleNextMonthDateRangeTrigger = useCallback(() => {
        let endDate = Utils.getMonthEnd(1);
        if (campaign.endDate && campaign.endDate > endDate) {
            endDate = campaign.endDate;
        }

        if (isStartDateDisabled) {
            props.onChange({
                endDate: endDate,
            });
        } else {
            props.onChange({
                startDate: Utils.getMonthStart(1),
                endDate: endDate,
            });
        }
    }, [campaign, isStartDateDisabled]);
    return (
        <Card id="campaign-wizard-setup-delivery" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Schedule & Budget
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormLabel required={true}>Select a Date Range</FormLabel>
                        </Grid>

                        <Grid item xs={12}>
                            <Row>
                                <Box sx={{ width: 200 }}>
                                    <FormControl error={hasError('startDate')} fullWidth size="small">
                                        <CDDatePicker
                                            disablePast
                                            value={campaign.startDate}
                                            onChange={(dateValue: Date | null) =>
                                                handleDateChange('startDate', dateValue)
                                            }
                                            showError={hasError('startDate')}
                                            label="Start Date"
                                            disabled={isStartDateDisabled}
                                            minDate={startDateMinDate}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ width: 200 }}>
                                    <FormControl error={hasError('endDate')} fullWidth size="small">
                                        <CDDatePicker
                                            disablePast
                                            value={campaign.endDate}
                                            onChange={(dateValue: Date | null) =>
                                                handleDateChange('endDate', dateValue)
                                            }
                                            showError={hasError('endDate')}
                                            label="End Date"
                                            disabled={isCompletedCampaign}
                                            minDate={campaign.startDate}
                                        />
                                    </FormControl>
                                </Box>

                                {!isCompletedCampaign && (
                                    <>
                                        <CDButton variant="text" onClick={handleNextMonthDateRangeTrigger}>
                                            Next Month
                                        </CDButton>
                                    </>
                                )}
                            </Row>
                        </Grid>
                    </Grid>

                    <Divider />

                    <FormControl>
                        <Stack direction={!userContext.isAdmin() ? 'row' : 'column'} gap={2}>
                            {!userContext.isAdmin() && (
                                <Row sx={{ width: 140 }}>
                                    <FormControl error={hasError('budget')} size="small" fullWidth>
                                        <CDCurrencyField
                                            variant="outlined"
                                            label="Budget"
                                            name="budget"
                                            required={!impressionBased}
                                            error={hasError('budget')}
                                            // value={campaign.budget > 0 ? campaign.budget : ''}
                                            value={campaignBudget}
                                            onChange={handleChangeBudget}
                                            disabled={impressionBased}
                                            inputProps={{
                                                min: 5,
                                            }}
                                        />
                                    </FormControl>
                                </Row>
                            )}
                            {userContext.isAdmin() && (
                                <>
                                    {(isNewCampaign || isDraftCampaign) && (
                                        <FormLabel error={hasError('impressionBased')} required={true} sx={{ mb: 2 }}>
                                            Select a Budget or Impression Goal
                                        </FormLabel>
                                    )}

                                    <Column gap={1}>
                                        <Row gap={0} sx={{ width: 220 }}>
                                            {(isNewCampaign || isDraftCampaign) && (
                                                <Column gap={0}>
                                                    <RadioGroup
                                                        name="impressionBased"
                                                        defaultValue={false}
                                                        value={impressionBased}
                                                        onChange={() => {
                                                            setImpressionBased(!impressionBased);
                                                        }}
                                                    >
                                                        <Row gap={0}>
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio />}
                                                                label="Set a Budget"
                                                                labelPlacement="end"
                                                                sx={{ width: 180 }}
                                                            />
                                                        </Row>

                                                        <Row gap={0}>
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio />}
                                                                label="Set Impression Goal"
                                                                labelPlacement="end"
                                                                sx={{ width: 200 }}
                                                            />
                                                        </Row>
                                                    </RadioGroup>
                                                </Column>
                                            )}

                                            <Column gap={0}>
                                                <Row gap={0} sx={{ minHeight: 40 }}>
                                                    {!impressionBased && (
                                                        <Row sx={{ width: 'auto' }}>
                                                            <FormControl
                                                                error={hasError('budget')}
                                                                size="small"
                                                                sx={{ width: 140 }}
                                                            >
                                                                <CDCurrencyField
                                                                    variant="outlined"
                                                                    label="Budget"
                                                                    name="budget"
                                                                    required={!impressionBased}
                                                                    error={hasError('budget')}
                                                                    // value={campaign.budget > 0 ? campaign.budget : ''}
                                                                    value={campaignBudget}
                                                                    onChange={handleChangeBudget}
                                                                    disabled={impressionBased}
                                                                    inputProps={{
                                                                        min: 5,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Row>
                                                    )}
                                                </Row>

                                                <Row gap={0} sx={{ minHeight: 40 }}>
                                                    {impressionBased && (
                                                        <Row sx={{ width: 'auto' }}>
                                                            <Row sx={{ width: 'auto' }}>
                                                                <FormControl
                                                                    error={hasError('impressionGoal')}
                                                                    size="small"
                                                                    sx={{ width: 140 }}
                                                                >
                                                                    <CDTextField
                                                                        type="number"
                                                                        variant="outlined"
                                                                        label="Impressions"
                                                                        name="impressionGoal"
                                                                        required={true}
                                                                        error={hasError('impressionGoal')}
                                                                        value={campaign.impressionGoal ?? ''}
                                                                        onChange={handleChangeImpression}
                                                                    />
                                                                </FormControl>
                                                            </Row>

                                                            {(isNewCampaign || isDraftCampaign) && (
                                                                <Column gap={0} sx={{ width: 140 }}>
                                                                    <Typography
                                                                        color="text.secondary"
                                                                        variant="caption"
                                                                    >
                                                                        Estimated Budget
                                                                    </Typography>
                                                                    <Typography
                                                                        color="text.secondary"
                                                                        variant="caption"
                                                                    >
                                                                        {campaignBudget
                                                                            ? Utils.formatCurrency(
                                                                                  parseFloat(campaignBudget)
                                                                              )
                                                                            : ''}
                                                                    </Typography>
                                                                </Column>
                                                            )}
                                                        </Row>
                                                    )}
                                                </Row>
                                            </Column>
                                        </Row>

                                        {impressionBased && (
                                            <Typography color="text.secondary" variant="caption">
                                                Note: Actual budget may need to be adjusted in the DSP after campaign
                                                creation.
                                            </Typography>
                                        )}
                                    </Column>
                                </>
                            )}

                            <CampaignDuplicateLoader isLoading={isChangingCampaignBudget} />
                        </Stack>
                    </FormControl>

                    <Divider />

                    <FormControl>
                        <FormLabel error={hasError('budgetCapType')} required={true} sx={{ mb: 2 }}>
                            Select a Budget Cap
                        </FormLabel>

                        <RadioGroup
                            name="budgetCapType"
                            defaultValue="UNCAPPED"
                            value={campaign.budgetCapType}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="UNCAPPED" control={<Radio />} label="Uncapped" />

                            <Row gap={0}>
                                <FormControlLabel
                                    value="CUSTOM"
                                    control={<Radio />}
                                    label=""
                                    labelPlacement="end"
                                    sx={{
                                        '&': {
                                            marginRight: 0,
                                        },
                                    }}
                                />
                                <Row>
                                    <CDTextField
                                        select
                                        variant="outlined"
                                        name="budgetCapRecurrenceTimePeriod"
                                        required={campaign.budgetCapType === 'CUSTOM'}
                                        disabled={campaign.budgetCapType !== 'CUSTOM'}
                                        error={hasError('budgetCapRecurrenceTimePeriod')}
                                        value={campaign.budgetCapRecurrenceTimePeriod ?? 'DAILY'}
                                        onChange={handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                paddingLeft: 0,
                                            },
                                            width: 124,
                                        }}
                                    >
                                        <MenuItem value="DAILY">Daily</MenuItem>
                                        <MenuItem value="MONTHLY">Monthly</MenuItem>
                                    </CDTextField>

                                    {campaign.budgetCapType === 'CUSTOM' && (
                                        <CDCurrencyField
                                            variant="outlined"
                                            label="Amount"
                                            name="budgetCapAmount"
                                            required={campaign.budgetCapType === 'CUSTOM'}
                                            disabled={campaign.budgetCapType !== 'CUSTOM'}
                                            error={hasError('budgetCapAmount')}
                                            value={campaign.budgetCapAmount ?? ''}
                                            onChange={handleChange}
                                            sx={{ width: 104 }}
                                        />
                                    )}
                                </Row>
                            </Row>
                        </RadioGroup>
                    </FormControl>
                </Column>
            </CardContent>
        </Card>
    );
}
