import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Column from '../../../components/Column';
import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import { useCallback, useContext, useEffect, useState } from 'react';
import ApiService from '../../../ApiService';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { UserContext } from '../../../App';
import DataSetGridView from './DataSetGridView';
import AccessGuard from '../../../components/AccessGuard';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../../types/User';
import DataSetCardView from './DataSetCardView';
import { GridViewOutlined, ViewListOutlined } from '@mui/icons-material';
import { SectionHeading } from '../../../components/SectionHeading';
import Row from '../../../components/Row';
import { AdvertiserContext } from '../../../AdvertiserContext';

import { Dealer } from '../../../types/Dealer';
import AgencySelector from '../../../components/AgencySelector';
import DealerSelector from '../../../components/DealerSelector';

export default function DataSetPage() {
    const [dataSetSummaryList, setDataSetSummaryList] = useState<AdvertiserDataSetSummary[]>([]);

    const { userContext } = useContext(UserContext);
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);
    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    const [gridView, setGridView] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getDataSetSummary = useCallback(() => {
        setIsLoading(true);
        ApiService.getDataSetSummary(
            advertiserContext.agencyId,
            advertiserContext.dealer ? advertiserContext.dealer.id : null
        )
            .then((response) => {
                setDataSetSummaryList(response.data);
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false);
            });
    }, [advertiserContext]);

    useEffect(() => {
        getDataSetSummary();
    }, [advertiserContext.dealer, getDataSetSummary]);

    useEffect(() => {
        setAdvertiserContext(null, null);
        setDealer(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column sx={{ height: '100%' }}>
            <Column gap={1}>
                <AppBreadcrumbs items={[]} />

                <SectionHeading subheading={'Advertiser Submitted First Party Data'} divider={false}>
                    <Row gap={1} alignItems="center">
                        <Typography variant="h6">AMC Data Sets</Typography>
                    </Row>
                </SectionHeading>
            </Column>

            <Column gap={2} sx={{ height: '100%' }}>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <>
                        <Row>
                            <Box>
                                <AgencySelector
                                    variant="outlined"
                                    label="Agency"
                                    agencySelected={(agencyId) => {
                                        setAdvertiserContext(null, agencyId);
                                    }}
                                    agencyId={advertiserContext.agencyId}
                                />
                            </Box>

                            <Box>
                                <DealerSelector
                                    variant="outlined"
                                    label="Advertiser"
                                    agencyId={advertiserContext.agencyId}
                                    required={false}
                                    dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                                    dealerSelected={dealerSelected}
                                />
                            </Box>

                            <Row gap={1}>
                                <Tooltip title="List View">
                                    <Button
                                        sx={{
                                            minWidth: 0,
                                            color: gridView ? 'primary.dark' : 'primary.light',
                                        }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setGridView(!gridView)}
                                    >
                                        <ViewListOutlined />
                                    </Button>
                                </Tooltip>

                                <Tooltip title="Grid View">
                                    <Button
                                        sx={{
                                            minWidth: 0,
                                            color: !gridView ? 'primary.dark' : 'primary.light',
                                        }}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setGridView(!gridView)}
                                    >
                                        <GridViewOutlined />
                                    </Button>
                                </Tooltip>
                            </Row>
                        </Row>

                        {gridView && (
                            <>
                                <DataSetGridView
                                    isLoading={isLoading}
                                    dataSetSummaryList={dataSetSummaryList}
                                    agencyId={advertiserContext.agencyId}
                                    userContext={userContext}
                                    onCreateAudience={() => {
                                        getDataSetSummary();
                                    }}
                                />
                            </>
                        )}

                        {!gridView && (
                            <DataSetCardView
                                dataSetSummaryList={dataSetSummaryList}
                                agencyId={advertiserContext.agencyId}
                                userContext={userContext}
                            />
                        )}
                    </>
                </AccessGuard>
                <AccessGuard accessGroup={USER_GROUP_AGENCY}>
                    <>
                        <Row>
                            <Box>
                                <DealerSelector
                                    variant="outlined"
                                    label="Advertiser"
                                    agencyId={advertiserContext.agencyId}
                                    required={false}
                                    dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                                    dealerSelected={dealerSelected}
                                />
                            </Box>
                        </Row>

                        <DataSetCardView
                            dataSetSummaryList={dataSetSummaryList}
                            agencyId={advertiserContext.agencyId}
                            userContext={userContext}
                        />
                    </>
                </AccessGuard>
            </Column>
        </Column>
    );
}
