import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async () => {
    const { data } = await ApiService.getDspInventorySourceDeals();
    return data;
};

const useDspInventorySourceDealListQuery = () => {
    return useQuery(['dspInventorySourceDealList'], () => fetchData(), {
        staleTime: Infinity,
        enabled: false,
    });
};

export { useDspInventorySourceDealListQuery };
