import { styled } from '@mui/material';
import { ListItemButtonProps, ListItemTextProps, TypographyProps } from '@mui/material';
import {
    Card as BaseCard,
    CardContent as BaseCardContent,
    CardHeader as BaseCardHeader,
    FormLabel as BaseFormLabel,
    FormControlLabel as BaseFormControlLabel,
    ListItemButton as BaseListItemButton,
    ListItemText,
    StepButton as BaseStepButton,
    StepContent as BaseStepContent,
    StepLabel as BaseStepLabel,
    TableCell as BaseTableCell,
    ToggleButton as BaseToggleButton,
    Typography as BaseTypography,
} from '@mui/material';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { TablePrimaryCell as BaseTablePrimaryCell } from '../../../../components/Table';
import { getPaletteActionColor } from '../../../../theme';
import Row from '../../../../components/Row';

export const Card = styled(BaseCard)(({ theme }) => ({
    '&': {
        overflow: 'visible',
    },
}));

export const CardContent = styled(BaseCardContent)(({ theme }) => ({
    '&': {
        padding: theme.spacing(3),
    },
}));

export const CardHeader = styled(BaseCardHeader)(({ theme }) => ({
    '&': {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: theme.palette.divider,
    },
}));

export const CapitalizeTypography = styled((props: TypographyProps) => <BaseTypography variant="body2" {...props} />)({
    '&': {
        textTransform: 'capitalize',
    },
});

export const FormLabel = styled(BaseFormLabel)(({ theme }) => ({
    '&': {
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const FormControlLabel = styled(BaseFormControlLabel)(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.body2.fontSize,
    },
}));

export const ListItemButton = styled((props: ListItemButtonProps) => <BaseListItemButton dense {...props} />)(
    ({ theme }) => ({
        '&': {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(2),
        },
        '&.Mui-selected': {
            backgroundColor: getPaletteActionColor('primary', 'selected'),
            color: theme.palette.text.primary,
        },
        '&.Mui-selected:hover': {
            color: theme.palette.text.secondary,
        },
    })
);

export const ListItemLabel = (props: ListItemTextProps & { error?: boolean }) => {
    const { error, ...listItemTextProps } = props;

    return (
        <Row gap={1} alignItems="center" justifyContent="center">
            <ErrorOutlineIcon color="error" sx={{ visibility: error ? 'visible' : 'hidden' }} />
            <ListItemText {...listItemTextProps} />
        </Row>
    );
};

export const StepButton = styled(BaseStepButton)(({ theme }) => ({
    '&': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
    },
}));

export const StepContent = styled(BaseStepContent)(({ theme }) => ({
    '&': {
        borderLeft: 'none',
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
    },
}));

export const StepLabel = styled(BaseStepLabel)(({ theme }) => ({
    '&': {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    '& .Mui-completed .MuiSvgIcon-root': {
        color: theme.palette.success.main,
    },
}));

export const TablePrimaryCell = styled(BaseTablePrimaryCell)(({ theme }) => ({
    '&': {
        width: '40%',
    },
}));

export const TableCell = styled(BaseTableCell)(({ theme }) => ({
    '&': {
        width: '60%',
    },
}));

export const ToggleButton = styled(BaseToggleButton)(({ theme }) => ({
    '&': {
        textTransform: 'inherit',
    },
}));
