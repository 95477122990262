import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, RadioGroup } from '@mui/material';

import { Code } from '../../../types/Code';
import { RadioCard as BaseRadioCard, RadioCardProps, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCodeList } from '../../../hooks/Models/CodeList';

const RadioCard = styled((props: RadioCardProps) => (
    <BaseRadioCard
        CardProps={{
            sx: {
                '& .MuiCardContent-root': {
                    height: 122,
                },
            },
        }}
        {...props}
    />
))(({ theme }) => ({}));

type CampaignKPIRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignKPIRadioGroup(props: CampaignKPIRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;
    const { codes, defaultCode } = useCodeList({ codeGroup: 'KpiType' });
    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }
    }, [codes, filter]);

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'CLICK_THROUGH_RATE':
                return 'Click Through Rate (CTR)';

            case 'COST_PER_CLICK':
                return 'Cost Per Click (CPC)';

            case 'COST_PER_VIDEO_COMPLETION':
                return 'Cost Per Video Completion (CPCV)';

            case 'VIDEO_COMPLETION_RATE':
                return 'Video Completion Rate';

            case 'COST_PER_DETAIL_PAGE_VIEW':
                return 'Cost Per Detail Page View (CPDPV)';

            case 'DETAIL_PAGE_VIEW_RATE':
                return 'Detail Page View Rate (DPVR)';
        }

        return code.description;
    };

    const getCodeDescription = (code: Code): string => {
        switch (code.code) {
            case 'CLICK_THROUGH_RATE':
                return 'The number of click throughs relative to the number of impression';

            case 'COST_PER_CLICK':
                return 'The average cost for each click on ad';

            case 'COST_PER_VIDEO_COMPLETION':
                return 'The average cost to acquire a video complete conversion';

            case 'VIDEO_COMPLETION_RATE':
                return 'The number of video completions relative to the number of video starts';

            case 'COST_PER_DETAIL_PAGE_VIEW':
                return 'The average cost to acquire a detail page view for a promoted product on Amazon';

            case 'DETAIL_PAGE_VIEW_RATE':
                return 'The number of detail page views for a promoted product on Amazon relative to a number of ad impressions';

            case 'REACH':
                return 'Increase your reach to unique users.';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value ?? defaultValue}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={6}>
                                <RadioCard
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    description={getCodeDescription(code)}
                                    label={getCodeLabel(code)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
