import { useCallback, useEffect, useState } from 'react';

import { Campaign, CampaignAdGroupGeoLocation, CampaignAdGroupLocation } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { Location, ZipCode } from '../types/Location';
// import { parseZipCodes } from '../hooks/useZipCodes';
import { DspGeoLocation } from '../types/DspGeoLocation';
import { useDspGeoLocationListQuery } from '../hooks/Queries/DspGeoLocationListQuery';

export function toCampaignAdGroupGeoLocations({
    locations,
    zipCodes,
    adGroupGeoLocations = [],
}: {
    locations: Location[];
    zipCodes: ZipCode[];
    adGroupGeoLocations: CampaignAdGroupGeoLocation[];
}): CampaignAdGroupGeoLocation[] {
    return [
        ...adGroupGeoLocations.filter((x: CampaignAdGroupGeoLocation) => {
            const location: Location | undefined = locations.find((y: Location) => x.dspGeoLocationId === y.id);
            if (location) {
                return zipCodes.find((z: ZipCode) => location.zipCode === z.code) !== undefined;
            }
            return false;
        }),
        ...zipCodes
            .filter((x: ZipCode) => {
                const location: Location | undefined = locations.find((y: Location) => x.code === y.zipCode);
                if (location) {
                    return (
                        adGroupGeoLocations.find(
                            (y: CampaignAdGroupGeoLocation) => location.id === y.dspGeoLocationId
                        ) === undefined
                    );
                }
                return false;
            })
            .map((zipCode: ZipCode) => {
                const location: Location | undefined = locations.find((y: Location) => zipCode.code === y.zipCode);

                return {
                    dspGeoLocationId: location ? location.id : '',
                };
            })
            .filter((adGroupGeoLocation: CampaignAdGroupLocation) => {
                return adGroupGeoLocation.dspGeoLocationId !== '';
            }),
    ];
}

export function toCampaignAdGroupLocations(
    locations: Location[],
    adGroupLocations: CampaignAdGroupLocation[] = []
): CampaignAdGroupLocation[] {
    return [
        ...adGroupLocations.filter((x: CampaignAdGroupLocation) => {
            return locations.find((y: Location) => x.dspGeoLocationId === y.id) !== undefined;
        }),
        ...locations
            .filter((x: Location) => {
                return adGroupLocations.find((y: CampaignAdGroupLocation) => x.id === y.dspGeoLocationId) === undefined;
            })
            .map((location: Location) => {
                return {
                    dspGeoLocationId: location.id,
                };
            }),
    ];
}

export function toLocations(adGroupLocations: CampaignAdGroupLocation[]): Location[] {
    let locations: Location[] = [];

    if (adGroupLocations.length > 0) {
        locations.push(
            ...adGroupLocations.map((adGroupLocation: CampaignAdGroupLocation) => {
                return {
                    id: adGroupLocation.dspGeoLocationId,
                    name: '',
                    category: '',
                    radius: 30,
                };
            })
        );
    }

    return locations;
}

export const parseZipCode = (location: Location): string => {
    let zipCode = '';

    if (location.category === 'POSTAL_CODE') {
        if (location.name) {
            const parts = location.name.split('-').map((i: string) => i.trim());

            if (parts.length >= 2) {
                zipCode = parts[parts.length - 1];
            }
        }
    }

    return zipCode;
};

export function toDspGeoLocationDTO(dspGeoLocation: DspGeoLocation): Location {
    const dspGeoLocationDTO: Location = {
        id: dspGeoLocation.geoId,
        name: dspGeoLocation.name,
        category: dspGeoLocation.category,
        zipCode: dspGeoLocation.zipcode,
        radius: 0,
    };

    return dspGeoLocationDTO;
}

export interface Locations {
    //
    locations: Location[];
    setLocations(locations: Location[]): void;
    isFetchingLocations: boolean;
    fetchLocations(): void;

    //
    getLocationName(location: Location): string;
    getLocationByZipCode(zipCode: ZipCode): Location | undefined;
    getLocationByRawZipCode(rawZipCode: string): Location | undefined;
    getCampaignLocations(campaign: Campaign): Location[];
    getCampaignGeoLocations(campaign: Campaign): Location[];

    //
    hydrateLocation(unhydratedLocation: Location): Location;
    hydrateLocations(unhydratedLocations: Location[]): Location[];
}

type useLocationProps = {
    dealer: Dealer | null | undefined;
};

export default function useLocations(props: useLocationProps): Locations {
    const [locations, setLocations] = useState<Location[]>([]);
    const { data, refetch, isFetching } = useDspGeoLocationListQuery();

    const fetchLocations = useCallback(() => {
        if (!data) {
            refetch();
        }
    }, [data, refetch]);

    useEffect(() => {
        setLocations(data ? data.map((model: DspGeoLocation) => toDspGeoLocationDTO(model)) : []);
    }, [data]);

    const getLocationName = (location: Location): string => {
        return hydrateLocation(location)?.name;
    };

    const hydrateLocation = (unhydratedLocation: Location): Location => {
        if (locations && locations.length > 0) {
            const hydratedLocation = locations.find((o: Location) => o.id === unhydratedLocation.id);

            if (hydratedLocation) {
                return hydratedLocation;
            }
        }

        return unhydratedLocation;
    };

    const hydrateLocations = (unhydratedLocations: Location[]): Location[] => {
        if (locations && locations.length > 0) {
            return unhydratedLocations.map((unhydratedLocation: Location) => hydrateLocation(unhydratedLocation));
        }

        return unhydratedLocations;
    };

    const getCampaignGeoLocations = (campaign: Campaign): Location[] => {
        let hydratedLocations: Location[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupGeoLocations) {
                hydratedLocations = hydrateLocations(toLocations(campaign.adGroups[0].adGroupGeoLocations));
            }
        }

        return hydratedLocations;
    };

    const getCampaignLocations = (campaign: Campaign): Location[] => {
        let hydratedLocations: Location[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupLocations) {
                hydratedLocations = hydrateLocations(toLocations(campaign.adGroups[0].adGroupLocations));
            }
        }

        return hydratedLocations;
    };

    const getLocationByZipCode = (zipCode: ZipCode): Location | undefined => {
        return locations.find((location: Location) => location.zipCode === zipCode.code);
    };

    const getLocationByRawZipCode = (rawZipCode: string): Location | undefined => {
        return locations.find((location: Location) => location.zipCode === rawZipCode);
    };

    return {
        locations,
        setLocations,
        fetchLocations,
        isFetchingLocations: isFetching,
        getLocationName,
        hydrateLocation,
        hydrateLocations,
        getLocationByZipCode,
        getLocationByRawZipCode,
        getCampaignGeoLocations,
        getCampaignLocations,
    };
}
