import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ApiService from '../ApiService';
import { Code } from '../types/Code';
import { FormControl, InputAdornment, MenuItem, TextFieldProps, Typography } from '@mui/material';
import { HelpOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { CDTextField } from './CDTextField';
import StylableTooltip from './StylableTooltip';
import theme from '../theme';

export interface CodeLookupComponentProps {
    label: string;
    codeGroup: string;
    value?: string | null;
    error?: boolean;
    includeNone?: boolean;
    required?: boolean;
    onChange(code: string): void;
    filter?(codes: Code[]): Code[];
    applyFilter?: string | number | boolean;
    help?: ReactNode;
    disabled?: boolean;
    variant?: TextFieldProps['variant'];
}

const HtmlTooltip = styled(StylableTooltip)(() => ({
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: theme.palette.text.primary,
}));

export default function CodeLookupComponent(props: CodeLookupComponentProps) {
    const { label, codeGroup, value, error, includeNone, required } = props;

    const [allCodes, setAllCodes] = useState<Code[] | null>(null);
    const [codes, setCodes] = useState<Code[] | null>(null);
    const propsRef = useRef(props);

    useEffect(() => {
        propsRef.current = props;
    }, [props]);

    const handleChange = useCallback(
        (newValue: string) => {
            if (!props.disabled) {
                setTimeout(() => {
                    propsRef.current.onChange(newValue === 'NONE' ? '' : newValue);
                }, 100);
            }
        },
        [props.disabled]
    );

    const filterCodeList = useCallback(
        (codeList: Code[]) => {
            let codes = codeList;
            if (propsRef.current.filter) {
                codes = propsRef.current.filter(codeList);
            }
            if (includeNone) {
                codes = [
                    {
                        id: -1,
                        codeGroup: codeGroup,
                        code: 'NONE',
                        description: '-- Select an Option --',
                        defaultOption: true,
                        sortOrder: 0,
                    },
                    ...codes,
                ];
            }
            setCodes(codes);
            if (codes.length === 1) {
                handleChange(codes[0].code);
            }

            if (codes.length === 1 && value !== codes[0].code) {
                handleChange(codes[0].code);
            } else {
                const defaultOption = codes.filter((code) => code.defaultOption);
                if (!value && defaultOption.length > 0) {
                    handleChange(defaultOption[0].code);
                }
            }
        },
        [codeGroup, handleChange, includeNone, value]
    );

    useEffect(() => {
        if (allCodes) {
            filterCodeList(allCodes);
        }
    }, [allCodes, filterCodeList, props.applyFilter]);

    useEffect(() => {
        ApiService.getCodes(codeGroup)
            .then((response) => {
                let codes = response.data;
                setAllCodes(codes);
                filterCodeList(codes);
            })
            .catch();
    }, [codeGroup, filterCodeList]);

    let helpAdornment = <></>;
    if (props.help) {
        helpAdornment = (
            <InputAdornment position="end" sx={{ position: 'absolute', right: '30px' }}>
                <HtmlTooltip
                    disableInteractive
                    title={
                        <>
                            <Typography color="inherit">{label}</Typography>
                            {props.help}
                        </>
                    }
                >
                    <HelpOutlined color="primary" />
                </HtmlTooltip>
            </InputAdornment>
        );
    }

    return (
        <FormControl fullWidth size="small">
            <CDTextField
                label={label}
                select
                required={required}
                SelectProps={{ native: false }}
                value={codes ? value : ''}
                disabled={props.disabled}
                error={error}
                variant={props.variant}
                InputProps={{
                    endAdornment: helpAdornment,
                }}
                onChange={(event) => {
                    handleChange(event.target.value);
                }}
                InputLabelProps={{ shrink: true }}
            >
                {codes ? (
                    codes.map((code) => (
                        <MenuItem key={code.code} value={code.code}>
                            {code.description}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value=""></MenuItem>
                )}
            </CDTextField>
        </FormControl>
    );
}
