import { useQuery } from 'react-query';
import axios from 'axios';

const fetchData = async () => {
    const { data } = await axios.get('/api/geographicTargeting/token');
    return data;
};

const useMapTokenQuery = () => {
    return useQuery(['mapToken'], () => fetchData(), {
        staleTime: Infinity,
        enabled: true,
    });
};

export { useMapTokenQuery };
