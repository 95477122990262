import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async () => {
    const { data } = await ApiService.getDspGeoLocations();
    return data;
};

const useDspGeoLocationListQuery = () => {
    return useQuery(['dspGeoLocationList'], () => fetchData(), {
        staleTime: Infinity,
        enabled: false,
    });
};

export { useDspGeoLocationListQuery };
