import { useState } from 'react';
import { Box, IconButton } from '@mui/material/';
import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';

import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import { Agency, AgencyFirstPartyDealer } from '../../types/Agency';
import { useInfoMessage } from '../../hooks/useInfoMessage';
import ApiService from '../../ApiService';

// import { TABS } from './campaignDetailView/CampaignDetailsPage';

export default function AgencyFirstPartyDealerDataGridItemActions(props: {
    agency: Agency;
    agencyFirstPartyDealer: AgencyFirstPartyDealer;
    onEdit(agencyFirstPartyDealer: AgencyFirstPartyDealer): void;
    onDelete(agencyFirstPartyDealer: AgencyFirstPartyDealer, agency: Agency): void;
}) {
    const { agency, agencyFirstPartyDealer } = props;

    const { showInfoMessage } = useInfoMessage();

    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    function deleteAgencyFirstPartyDealer() {
        let agencyFirstPartyDealers: AgencyFirstPartyDealer[] = [...agency.agencyFirstPartyDealers];

        if (agencyFirstPartyDealer?.id) {
            const agencyFirstPartyDealerIndex: number = agencyFirstPartyDealers.findIndex(
                (a: AgencyFirstPartyDealer) => a.id === agencyFirstPartyDealer.id
            );

            if (agencyFirstPartyDealerIndex >= 0) {
                agencyFirstPartyDealers.splice(agencyFirstPartyDealerIndex, 1);
            }
        }

        if (agencyFirstPartyDealer?.id) {
            ApiService.updateAgency({
                ...agency,
                agencyFirstPartyDealers: agencyFirstPartyDealers,
            })
                .then((response) => {
                    showInfoMessage('success', 'Agency Advertiser mapping has been deleted.');

                    props.onDelete({ ...agencyFirstPartyDealer }, response.data);
                })
                .catch(() => {});
        }
    }

    function hideConfirm() {
        setConfirmDialogProps(null);
    }

    function showDeleteConfirm() {
        setConfirmDialogProps({
            title: 'Delete this Agency Advertiser Mapping?',
            text: '',
            onOk: () => handleDelete(),
            okText: 'Delete',
            okColor: 'error',
            okIcon: <DeleteOutlinedIcon />,
            onCancel: () => hideConfirm(),
        });
    }

    const handleDeleteConfirm = () => {
        showDeleteConfirm();
    };

    const handleDelete = () => {
        hideConfirm();
        deleteAgencyFirstPartyDealer();
    };

    const handleEdit = () => {
        if (props.onEdit) {
            props.onEdit(agencyFirstPartyDealer);
        }
    };

    return (
        <Box>
            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        okColor={confirmDialogProps.okColor}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    />
                </>
            )}

            <IconButton size="small" aria-label="delete" onClick={handleDeleteConfirm}>
                <DeleteOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" aria-label="edit" onClick={handleEdit}>
                <EditIcon fontSize="small" />
            </IconButton>
        </Box>
    );
}
