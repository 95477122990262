import { Backdrop, Box, Button, CircularProgress, Divider, FormControl, Grid, Stack, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { CloseOutlined, DeleteOutlined, SaveOutlined } from '@mui/icons-material';
import { Agency, AgencyFirstPartyDealer } from '../../types/Agency';
import { Dealer } from '../../types/Dealer';
import ApiService from '../../ApiService';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import DealerSelector from '../../components/DealerSelector';
import { useInfoMessage } from '../../hooks/useInfoMessage';
import { useNavigate } from 'react-router-dom';

type AgencyFirstPartyDealerFormProps = {
    save?: boolean;
    size?: 'lg' | 'md' | 'sm';
    hideActions?: boolean;
    agency: Agency;
    agencyFirstPartyDealer: AgencyFirstPartyDealer;
    // onSubmit: () => void;
    onValidate?: (validated: boolean) => void;
    onSave: (agencyFirstPartyDealer: AgencyFirstPartyDealer, agency: Agency) => void;
};

export default function AgencyFirstPartyDealerForm(props: AgencyFirstPartyDealerFormProps) {
    const { agency, onValidate } = props;
    const { showInfoMessage } = useInfoMessage();
    const navigate = useNavigate();

    useEffect(() => {
        setAgencyFirstPartyDealer(props.agencyFirstPartyDealer);
    }, [props.agencyFirstPartyDealer]);

    const [agencyFirstPartyDealer, setAgencyFirstPartyDealer] = useState<AgencyFirstPartyDealer>(
        props.agencyFirstPartyDealer
    );
    const [busy, setBusy] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const validated: boolean | undefined = useMemo(() => {
        if (agencyFirstPartyDealer) {
            if (!agencyFirstPartyDealer?.agencyId) {
                return false;
            }

            if (!agencyFirstPartyDealer?.dealerId) {
                return false;
            }

            if (!agencyFirstPartyDealer?.dealerCode) {
                return false;
            }

            if (!isDirty) {
                return false;
            }

            return true;
        }

        return false;
    }, [agencyFirstPartyDealer, isDirty]);

    useEffect(() => {
        if (props.save === true) {
            submit();
        }
    }, [props.save]);

    useEffect(() => {
        if (onValidate) {
            onValidate(validated);
        }
    }, [onValidate, validated]);

    const showActions: boolean | undefined = useMemo(() => {
        return props.hideActions ? false : true;
    }, [props.hideActions]);

    const selectDealer = useCallback((dealerId: number | null, dealer: Dealer) => {
        if (dealerId) {
            setAgencyFirstPartyDealer((value) => {
                return { ...value, dealerId: dealerId, dealer: dealer };
            });
        }
    }, []);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        let value = event.target.value;

        switch (field) {
            case 'dealerCode':
                value = value.replace(/\s/g, ''); // Remove spaces
                break;
        }

        setAgencyFirstPartyDealer({ ...agencyFirstPartyDealer, [field]: value });
        setDirty(true);
        if (field === 'name') {
        }
    }

    function submit() {
        let agencyFirstPartyDealers: AgencyFirstPartyDealer[] = [...agency.agencyFirstPartyDealers];

        if (agencyFirstPartyDealer?.id) {
            const agencyFirstPartyDealerIndex: number = agencyFirstPartyDealers.findIndex(
                (a: AgencyFirstPartyDealer) => a.id === agencyFirstPartyDealer.id
            );

            if (agencyFirstPartyDealerIndex >= 0) {
                agencyFirstPartyDealers[agencyFirstPartyDealerIndex] = agencyFirstPartyDealer;
            }
        } else {
            agencyFirstPartyDealers.push(agencyFirstPartyDealer);
        }

        ApiService.updateAgency({
            ...agency,
            agencyFirstPartyDealers: agencyFirstPartyDealers,
        })
            .then((response) => {
                if (agencyFirstPartyDealer?.id) {
                    showInfoMessage('success', 'Agency Advertiser mapping has been updated.');
                } else {
                    showInfoMessage('success', 'Agency Advertiser mapping has been created.');
                }

                props.onSave({ ...agencyFirstPartyDealer }, response.data);
            })
            .catch(() => {});
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        submit();
    }

    function showDeleteConfirm() {
        setConfirmDialogProps({
            title: 'Delete this Agency Advertiser Mapping?',
            text: '',
            onOk: () => deleteAgencyFirstPartyDealer(),
            okText: 'Delete',
            okColor: 'error',
            okIcon: <DeleteOutlined />,
            onCancel: () => hideConfirm(),
        });
    }

    function hideConfirm() {
        setConfirmDialogProps(null);
    }

    function deleteAgencyFirstPartyDealer() {
        hideConfirm();
        setBusy(true);

        let agencyFirstPartyDealers: AgencyFirstPartyDealer[] = [...agency.agencyFirstPartyDealers];

        if (agencyFirstPartyDealer?.id) {
            const agencyFirstPartyDealerIndex: number = agencyFirstPartyDealers.findIndex(
                (a: AgencyFirstPartyDealer) => a.id === agencyFirstPartyDealer.id
            );

            if (agencyFirstPartyDealerIndex >= 0) {
                agencyFirstPartyDealers.splice(agencyFirstPartyDealerIndex, 1);
            }
        }

        if (agencyFirstPartyDealer?.id) {
            ApiService.updateAgency({
                ...agency,
                agencyFirstPartyDealers: agencyFirstPartyDealers,
            })
                .then((response) => {
                    showInfoMessage('success', 'Agency Advertiser mapping has been deleted.');
                })
                .catch((error) => {
                    showInfoMessage('error', error.message);
                });
        }
    }

    let uploadMessage;
    if (busy) {
        uploadMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    return (
        <>
            <form method="post" onSubmit={handleSubmit}>
                {uploadMessage}

                {confirmDialogProps && (
                    <>
                        <ConfirmDialog
                            title={confirmDialogProps.title}
                            text={confirmDialogProps.text}
                            okText={confirmDialogProps.okText}
                            okIcon={confirmDialogProps.okIcon}
                            okColor={confirmDialogProps.okColor}
                            onCancel={confirmDialogProps.onCancel}
                            onOk={confirmDialogProps.onOk}
                        />
                    </>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <FormControl
                            sx={{
                                width: '100%',
                                '& .MuiAutocomplete-root': {
                                    width: 'auto',
                                },
                            }}
                        >
                            <DealerSelector
                                label="Advertiser"
                                agencyId={agencyFirstPartyDealer.agencyId}
                                dealerId={agencyFirstPartyDealer.dealerId}
                                dealerSelected={selectDealer}
                                variant="outlined"
                                TextFieldProps={{
                                    shrinkLabel: true,
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={props.size === 'md' ? 7 : 5} />

                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            name="dealerCode"
                            label="Agency Advertiser Id"
                            value={agencyFirstPartyDealer.dealerCode}
                            required={true}
                            fullWidth={true}
                            size="small"
                            variant="outlined"
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={props.size === 'md' ? 7 : 5} />

                    <Grid item xs={12}></Grid>
                </Grid>

                {showActions && (
                    <>
                        <Divider />

                        <Grid container spacing={2}>
                            <Grid item md={12} style={{ marginTop: '30px' }}></Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        disabled={!isDirty}
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        startIcon={<SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate('/agencyFirstPartyDealers')}
                                        color="error"
                                        startIcon={<CloseOutlined />}
                                    >
                                        Cancel
                                    </Button>
                                    <div style={{ flex: 1 }} />
                                    {agencyFirstPartyDealer?.id && (
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteOutlined />}
                                            onClick={() => showDeleteConfirm()}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                )}
            </form>

            <Box sx={{ minHeight: '100%', p: 1 }}></Box>
        </>
    );
}
