/**
 */
export interface FtCampaign {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    isClosed: boolean;
    isFavorite: boolean;
}

export interface FtPlacement {
    id: number;
    name: string;
    dimensions: string;
}

export interface AdServerPlacement {
    id: number;
    fileNameKey: string;
    libraryId: number;
    importSourceId: number;
    adServerCampaignId: number;
    adServerCampaignName: string;
    placementId: number;
    placementName: string;
    creativeId: number;
    fileName: string;
    status: string;
    createdDate: Date;
    updatedDate: Date;
}

export interface AdServerImportSource {
    id: number;
    s3Bucket: string;
    s3IncomingPath: string;
    s3DeliveredPath: string;
    s3Region: string;
}

export enum AdServerPlacementStatus {
    CONNECTED = 'CONNECTED',
    UPLOADING = 'UPLOADING',
    TRANSCODING = 'TRANSCODING',
    UNSUBMITTED = 'UNSUBMITTED',
    APPROVED = 'APPROVED',
    REPLACED = 'REPLACED',
}
