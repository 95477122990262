import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid';
import { Chip, Tooltip } from '@mui/material';
import CDGrid from '../../../components/CDGrid';
import DataSetDetailsDialog from './DataSetDetailsDialog';
import { UserContextValue } from '../../../App';
import { useState } from 'react';
import { AddOutlined, ShowChartOutlined } from '@mui/icons-material';
import ApiService from '../../../ApiService';
import AmcAudienceIcon from './AmcAudienceIcon';

export default function DataSetGridView(props: {
    dataSetSummaryList: AdvertiserDataSetSummary[];
    agencyId: number | null;
    onCreateAudience(): void;
    userContext: UserContextValue;
    isLoading?: boolean;
}) {
    const { dataSetSummaryList, agencyId, onCreateAudience, isLoading = false } = props;

    const [dataSetSummary, setDataSetSummary] = useState<AdvertiserDataSetSummary | null>(null);

    let columns: GridColumns = [
        {
            width: 170,
            field: 'agencyName',
            headerName: 'Agency',
        },
        {
            width: 230,
            field: 'dealerName',
            headerName: 'Advertiser',
        },
        {
            width: 100,
            field: 'dataSetName',
            headerName: 'Data Set',
        },
        {
            width: 110,
            field: 'submittedRecords',
            type: 'number',
            headerName: 'Submitted',
        },
        {
            width: 110,
            field: 'identifiedRecords',
            type: 'number',
            headerName: 'Identified',
            renderCell: (params) => {
                const value = params.value;
                const tooltip =
                    value >= 2000
                        ? 'This Audience meets the minimum size requirement for use in a Campaign'
                        : 'An Audience must have at least 2000 members for use in a Campaign';
                return (
                    <Tooltip title={tooltip}>
                        <Chip
                            variant="outlined"
                            label={Number(value).toLocaleString()}
                            size="small"
                            color={value >= 2000 ? 'success' : value >= 1750 ? 'warning' : 'error'}
                        />
                    </Tooltip>
                );
            },
        },
        {
            width: 120,
            field: 'amcAudience',
            headerName: 'AMC Audience',
            align: 'center',
            renderCell: (params) => {
                if (params.value) {
                    return <AmcAudienceIcon amcAudience={params.value} />;
                } else {
                    return null;
                }
            },
        },
        {
            width: 120,
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            headerAlign: 'left',
            align: 'left',
            getActions: (params: GridRowParams) => {
                let actions = [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Display 6 Month History">
                                <ShowChartOutlined />
                            </Tooltip>
                        }
                        label=""
                        onClick={() => {
                            displayDataSet(params.row);
                        }}
                    />,
                ];
                if (params.row.identifiedRecords >= 2000 && !params.row.amcAudience) {
                    actions.push(
                        <GridActionsCellItem
                            icon={
                                <Tooltip title="Create a new AMC Audience">
                                    <AddOutlined color="primary" />
                                </Tooltip>
                            }
                            label=""
                            onClick={() => {
                                createAmcAudience(params.row.dealerId, params.row.dataSetName);
                            }}
                            showInMenu={undefined}
                        />
                    );
                }
                return actions;
            },
        },
    ];

    function createAmcAudience(dealerId: number, dataSetId: string) {
        ApiService.createAmcAudience(dealerId, dataSetId)
            .then((response) => {
                onCreateAudience();
            })
            .catch(() => {});
    }

    function displayDataSet(dataSetSummary: AdvertiserDataSetSummary): void {
        setDataSetSummary(dataSetSummary);
    }

    return (
        <>
            <CDGrid
                loading={isLoading}
                columns={columns}
                rows={agencyId ? dataSetSummaryList.filter((s) => s.agencyId === agencyId) : dataSetSummaryList}
                autoHeight={false}
                autoPageSize={true}
            />

            <DataSetDetailsDialog dataSetSummary={dataSetSummary} onClose={() => setDataSetSummary(null)} />
        </>
    );
}
