import { useCallback, useEffect, useReducer } from 'react';
import { useCampaignCreativeListQuery } from '../Queries/CampaignCreativeListQuery';

const ACTION_TYPES = {
    SET_STATE: 'SET_STATE',
};

type ActionType = keyof typeof ACTION_TYPES;

interface State {
    campaignCreatives: string[];
}

interface Action {
    type: ActionType | string;
    payload?: Partial<State>;
}

interface UseState extends State {
    fetchCampaignCreatives: () => void;
    setCampaignCreatives: (campaignCreatives: string[]) => void;
    isFetchingCampaignCreatives: boolean;
    isLoadingCampaignCreatives: boolean;
}

interface UseStateProps {
    campaignId: number;
    campaignChangeRequestId?: number;
    campaignCreatives?: string[];
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

const initialState: State = {
    campaignCreatives: [],
};

const useCampaignCreativeList = (props: UseStateProps): UseState => {
    const { campaignId, campaignChangeRequestId, campaignCreatives = [] } = props;
    const [state, dispatch] = useReducer(reducer, { ...initialState, campaignCreatives });
    const { data, refetch, isFetching, isLoading } = useCampaignCreativeListQuery(campaignId, campaignChangeRequestId);

    const setCampaignCreatives = (campaignCreatives: string[]): void => {
        dispatch({ type: ACTION_TYPES.SET_STATE, payload: { campaignCreatives } });
    };

    const fetchCampaignCreatives = useCallback((): void => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setCampaignCreatives(data || []);
    }, [data]);

    return {
        ...state,

        isFetchingCampaignCreatives: isFetching,
        isLoadingCampaignCreatives: isLoading,

        fetchCampaignCreatives,
        setCampaignCreatives,
    };
};

export { ACTION_TYPES as CampaignCreativeListActionTypes };
export type {
    ActionType as CampaignCreativeListActionType,
    Action as CampaignCreativeListAction,
    State as CampaignCreativeListState,
    UseState as UseCampaignCreativeListState,
};
export { useCampaignCreativeList };
