import { ChangeEvent } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Card, CardContent, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField } from '../../../../components/CDTextField';
import { Code } from '../../../../types/Code';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import CampaignCreativeAssets from './CampaignCreativeAssets';
import CampaignCreativeTypeRadioGroup from '../CampaignCreativeTypeRadioGroup';
import Column from '../../../../components/Column';

type CampaignCreativeTypeCardProps = {} & CampaignWizardStepProps;

export default function CampaignCreativeTypeCard(props: CampaignCreativeTypeCardProps) {
    const { campaign } = props;
    const $campaignWizard = useCampaignWizardContext();
    const { hasError } = $campaignWizard;
    const { setFiles } = $campaignWizard;
    const { generateCampaignName, isCompletedCampaign, isDraftCampaign, isNewCampaign } = $campaignWizard;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            switch (event.target.name) {
                case 'campaignType':
                    campaign.campaignType = event.target.value;

                    props.onChange({
                        [event.target.name]: event.target.value,
                        campaignName: generateCampaignName(campaign),
                    });
                    break;

                default:
                    props.onChange({
                        [event.target.name]: event.target.value,
                    });
                    break;
            }
        }
    };

    const handleFileChange = (files: File[]) => {
        setFiles(files);
    };

    return (
        <Card id="campaign-wizard-creative" variant="outlined">
            <CardContent>
                <Column>
                    <FormControl error={hasError('campaignType')}>
                        <FormLabel required={true} sx={{ mb: 2 }}>
                            Select a Creative Type
                        </FormLabel>

                        <CampaignCreativeTypeRadioGroup
                            name="campaignType"
                            value={campaign.campaignType}
                            onChange={handleChange}
                            disabled={!isNewCampaign && !isDraftCampaign}
                            filter={(codes: Code[]): Code[] => {
                                return codes.filter((code: Code) => {
                                    switch (campaign.mediaType) {
                                        case 'Audio':
                                            return ['Static'].includes(code.code);

                                        default:
                                            return ['DCO', 'Static'].includes(code.code);
                                    }

                                    return false;
                                });
                            }}
                        />

                        {hasError('campaignType') && (
                            <FormHelperText error={true}>Campaign Type selection is required</FormHelperText>
                        )}
                    </FormControl>

                    {campaign.campaignType === 'Static' && (
                        <Column>
                            <CampaignCreativeAssets
                                campaign={campaign}
                                onFileChange={handleFileChange}
                                readOnly={isNewCampaign && isDraftCampaign}
                            />

                            <CDTextField
                                name="landingPage"
                                label="Destination URL"
                                value={campaign.landingPage ?? ''}
                                variant="outlined"
                                required={false}
                                disabled={isCompletedCampaign}
                                onChange={handleChange}
                            />
                        </Column>
                    )}

                    {campaign.campaignType === 'AdTag' && (
                        <Column>
                            <CDTextField
                                name="adTagUrl"
                                label="Ad Tag URL"
                                value={campaign.adTagUrl ?? ''}
                                variant="outlined"
                                required={true}
                                disabled={isCompletedCampaign}
                                onChange={handleChange}
                            />
                        </Column>
                    )}
                </Column>
            </CardContent>
        </Card>
    );
}
