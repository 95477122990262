import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';

import {
    AttributionStatus,
    AttributionType,
    PolkDealer,
    PolkDealerDealer,
    PolkSalesAnalysis,
    PolkSalesWorkflow,
} from '../types/Polk';
import { Performance } from '../types/CampaignPerformance';
import { Dealer } from '../types/Dealer';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

import { Metric } from '../components/Metrics/MetricCard';

export const TABS = {
    POLK_REPORTED_SALES: 0,
    ADVERTISER_REPORTED_SALES: 1,
};

export class AttributionMetrics {
    totalSales: Metric | null | undefined = null;
    attributedSales: Metric | null | undefined = null;
    totalSpend: Metric | null | undefined = null;
    costPerSold: Metric | null | undefined = null;
    totalImpressions: Metric | null | undefined = null;
    totalClickthroughs: Metric | null | undefined = null;
    totalImpressionFrequency: Metric | null | undefined = null;
    totalDailyReach: Metric | null | undefined = null;

    totalMarketShares: Metric | null | undefined = null;
    totalAdShares: Metric | null | undefined = null;
}

export class AttributionPageContextValue {
    dealer: Dealer | null = null;

    tab: number = 0;
    setTab: Function = (tab: number) => {};

    attributionType: AttributionType = 'Polk';
    setAttributionType: Function = (attributionType: AttributionType) => {};

    showPreviousAttributionDateMonth: boolean = false;
    attributionDate: Date = Utils.getMonthStart(0);
    setAttributionDate: Function = (attributionDate: Date) => {};

    attributionStatus: AttributionStatus = {
        salesWorkflows: [],
        inProgress: true,
    };
    setAttributionStatus: Function = (attributionStatus: AttributionStatus) => {};
    fetchAttributionStatus: Function = () => {};
    isFetchingAttributionStatus: boolean = false;

    attributionStatusUpdateDate: Date | null = null;
    setAttributionStatusUpdateDate: Function = (attributionStatusUpdateDate: Date | null) => {};
    attributionStatusUpdateDateFormatted: string | undefined = undefined;

    advertiserSalesUpdateDate: Date | null = null;
    setAdvertiserSalesUpdateDate: Function = (advertiserSalesUpdateDate: Date | null) => {};
    advertiserSalesUpdateDateFormatted: string | undefined = undefined;

    polkDealerDealers: PolkDealerDealer[] = [];
    setPolkDealerDealers: Function = (polkDealerDealers: PolkDealerDealer[]) => {};
    isAdvertiserPolkDealer: Function = (polkDealer: PolkDealer): boolean => false;
    isCompetitorPolkDealer: Function = (polkDealer: PolkDealer): boolean => false;
    getAdvertiserPolkDealerDealers: Function = (): PolkDealerDealer[] => [];
    getCompetitorPolkDealerDealers: Function = (): PolkDealerDealer[] => [];

    polkSalesAnalysis: PolkSalesAnalysis[] = [];
    setPolkSalesAnalysis: Function = (polkSalesAnalysis: PolkSalesAnalysis[]) => {};

    polkSalesWorkflow: PolkSalesWorkflow | null = null;
    setPolkSalesWorkflow: Function = (polkSalesWorkflow: PolkSalesWorkflow | null) => {};
    polkOpportunitiesWorkflow: PolkSalesWorkflow | null = null;
    setPolkOpportunitiesWorkflow: Function = (polkOpportunitiesWorkflow: PolkSalesWorkflow | null) => {};

    performances: Performance[] = [];
    setPerformances: Function = (performances: Performance[]) => {};
    previousPerformances: Performance[] = [];
    setPreviousPerformances: Function = (previousPerformances: Performance[]) => {};

    isFetchingPerformances: boolean = false;
    setIsFetchingPerformances: Function = (isFetchingPerformances: boolean) => {};
    isFetchingPreviousPerformances: boolean = false;
    setIsFetchingPreviousPerformances: Function = (isFetchingPerformances: boolean) => {};

    getPerformances: Function = (mediaType?: string) => {};
    getPerformanceMediaTypes: Function = () => {};
    getPerformanceLabel: Function = (performance: Performance) => {};

    attributionMetrics: AttributionMetrics = new AttributionMetrics();
    setAttributionMetrics: Function = (attributionMetrics: AttributionMetrics) => {};
}

export const AttributionPageContext = createContext<AttributionPageContextValue>(new AttributionPageContextValue());

export function useAttributionPageContext() {
    return useContext(AttributionPageContext);
}

export type AttributionPageProps = {
    dealer: Dealer | null;
};

export default function useAttributionPage(props: AttributionPageProps) {
    const { dealer } = props;

    const [tab, setTab] = useState<number>(TABS.POLK_REPORTED_SALES);
    const [attributionType, setAttributionType] = useState<AttributionType>('Polk');

    const showPreviousAttributionDateMonth: boolean = useMemo(() => {
        const now = moment();

        return now.diff(moment().startOf('month'), 'days') >= 7;
    }, []);

    const [attributionDate, setAttributionDate] = useState<Date>(() => {
        let _attributionDate = Utils.localStorage('attribution.attributionDate', null);

        if (_attributionDate) {
            _attributionDate = moment(_attributionDate).startOf('month').toDate();

            return _attributionDate;
        }

        return Utils.getMonthStart(showPreviousAttributionDateMonth ? -1 : -2);
    });

    const [attributionStatus, setAttributionStatus] = useState<AttributionStatus>({
        salesWorkflows: [],
        inProgress: true,
    });
    const [isFetchingAttributionStatus, setIsFetchingAttributionStatus] = useState<boolean>(false);

    const [attributionStatusUpdateDate, setAttributionStatusUpdateDate] = useState<Date | null>(null);

    const attributionStatusUpdateDateFormatted: string | undefined = useMemo(() => {
        if (attributionStatusUpdateDate) {
            return moment(attributionStatusUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [attributionStatusUpdateDate]);

    const [advertiserSalesUpdateDate, setAdvertiserSalesUpdateDate] = useState<Date | null>(null);
    const advertiserSalesUpdateDateFormatted = useMemo((): string | undefined => {
        if (advertiserSalesUpdateDate) {
            return moment(advertiserSalesUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [advertiserSalesUpdateDate]);

    const [polkDealerDealers, setPolkDealerDealers] = useState<PolkDealerDealer[]>([]);
    const [polkSalesAnalysis, setPolkSalesAnalysis] = useState<PolkSalesAnalysis[]>([]);

    const [polkSalesWorkflow, setPolkSalesWorkflow] = useState<PolkSalesWorkflow | null>(null);
    const [polkOpportunitiesWorkflow, setPolkOpportunitiesWorkflow] = useState<PolkSalesWorkflow | null>(null);

    const [performances, setPerformances] = useState<Performance[]>([]);
    const [previousPerformances, setPreviousPerformances] = useState<Performance[]>([]);

    const [isFetchingPerformances, setIsFetchingPerformances] = useState<boolean>(false);
    const [isFetchingPreviousPerformances, setIsFetchingPreviousPerformances] = useState<boolean>(false);

    const getPerformances = (mediaType?: string): Performance[] => {
        if (performances && performances.length) {
            return performances.filter((p: Performance) => p.mediaType === mediaType);
        }

        return [];
    };

    const getPerformanceMediaTypes = (): string[] => {
        if (performances && performances.length) {
            return performances
                .map((p: Performance) => p.mediaType)
                .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }

        return [];
    };

    const getPerformanceLabel = (p: Performance): string => {
        const labels = [];

        if (p?.mediaType) {
            labels.push(p.mediaType);
        }

        if (p?.campaignType) {
            labels.push(p.campaignType);
        }

        if (p?.strategyType) {
            labels.push(p.strategyType);
        }

        return labels.join(' ');
    };

    const [attributionMetrics, setAttributionMetrics] = useState<AttributionMetrics>(new AttributionMetrics());

    const fetchAttributionStatus = useCallback(() => {
        if (dealer?.id) {
            setIsFetchingAttributionStatus(true);
            ApiService.getAttributionStatus(dealer.id).then((response) => {
                setIsFetchingAttributionStatus(false);
                setAttributionStatus(response.data as AttributionStatus);
            });
        }
    }, [dealer]);

    const fetchPolkDealerDealers = useCallback(() => {
        if (dealer?.id) {
            ApiService.getPolkDealerDealers(dealer.id).then((response) => {
                setPolkDealerDealers(response.data);
            });
        } else {
            setPolkDealerDealers([]);
        }
    }, [dealer]);

    const isCompetitorPolkDealer = (polkDealer: PolkDealer): boolean => {
        let polkDealerDealer = null;

        if (polkDealerDealers && polkDealerDealers.length) {
            polkDealerDealer = polkDealerDealers.find((pdd: PolkDealerDealer) => pdd.polkDealer.id === polkDealer.id);
        }

        return polkDealerDealer ? polkDealerDealer.isCompetitor : false;
    };

    const isAdvertiserPolkDealer = (polkDealer: PolkDealer): boolean => {
        return isCompetitorPolkDealer(polkDealer) === false;
    };

    const getAdvertiserPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === false);
    };

    const getCompetitorPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === true);
    };

    useEffect(() => {
        if (attributionStatus?.salesWorkflows && attributionStatus.salesWorkflows.length) {
            setAttributionStatusUpdateDate(Utils.getDate(attributionStatus.salesWorkflows[0].updateDate));
        }
    }, [attributionStatus]);

    useEffect(() => {
        localStorage.setItem('attribution.attributionDate', moment(attributionDate).format('YYYY-MM-DD HH:mm:ss'));
    }, [attributionDate]);

    useEffect(() => {
        switch (tab) {
            case TABS.POLK_REPORTED_SALES:
                setAttributionType('Polk');
                break;

            case TABS.ADVERTISER_REPORTED_SALES:
                setAttributionType('Advertiser');
                break;
        }
    }, [tab, setAttributionType]);

    return {
        dealer,

        tab,
        setTab,

        attributionType,
        setAttributionType,

        showPreviousAttributionDateMonth,
        attributionDate,
        setAttributionDate,

        attributionStatus,
        setAttributionStatus,
        fetchAttributionStatus,
        isFetchingAttributionStatus,

        attributionStatusUpdateDate,
        setAttributionStatusUpdateDate,
        attributionStatusUpdateDateFormatted,

        advertiserSalesUpdateDate,
        setAdvertiserSalesUpdateDate,
        advertiserSalesUpdateDateFormatted,

        polkDealerDealers,
        setPolkDealerDealers,
        fetchPolkDealerDealers,
        isAdvertiserPolkDealer,
        isCompetitorPolkDealer,
        getAdvertiserPolkDealerDealers,
        getCompetitorPolkDealerDealers,

        polkSalesAnalysis,
        setPolkSalesAnalysis,

        polkSalesWorkflow,
        setPolkSalesWorkflow,
        polkOpportunitiesWorkflow,
        setPolkOpportunitiesWorkflow,

        performances,
        setPerformances,
        previousPerformances,
        setPreviousPerformances,

        isFetchingPerformances,
        setIsFetchingPerformances,
        isFetchingPreviousPerformances,
        setIsFetchingPreviousPerformances,

        getPerformances,
        getPerformanceMediaTypes,
        getPerformanceLabel,

        attributionMetrics,
        setAttributionMetrics,
    };
}
