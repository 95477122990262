import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async () => {
    const { data } = await ApiService.getForecastingParameters();
    return data;
};

const useForecastingParameterListQuery = () => {
    return useQuery(['forecastingParameterList'], () => fetchData(), {
        staleTime: Infinity,
        enabled: true,
    });
};

export { useForecastingParameterListQuery };
