import { useCallback, useEffect, useState } from 'react';

interface ScrollableSection {
    index: number;
    id: string;
}

interface ScrollableSectionPosition extends ScrollableSection {
    top: number;
}

interface State {
    activeSection: number;
}

interface UseState extends State {
    setActiveSection: (activeSection: number) => void;
    jumpToActiveSection: (section: number) => void;
}

interface UseScrollSpyProps {
    sections: ScrollableSection[];
}

const useScrollSpy = (props: UseScrollSpyProps): UseState => {
    const { sections } = props;
    const [activeSection, setActiveSection] = useState<number>(-1);

    const jumpToActiveSection = useCallback(
        (index: number) => {
            const section = sections.find((sec) => sec.index === index);
            if (section) {
                const el = document.getElementById(section.id);
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                setActiveSection(index);
            }
        },
        [sections]
    );

    useEffect(() => {
        const handleScroll = () => {
            const offsets: ScrollableSectionPosition[] = sections.map((section: ScrollableSection) => {
                const el = document.getElementById(section.id);
                if (!el) return { ...section, id: section.id, top: Infinity };
                const rect = el.getBoundingClientRect();
                return { ...section, id: section.id, top: Math.abs(rect.top) };
            });

            // Find the section with the smallest `top` value within the viewport
            const closest: ScrollableSectionPosition = offsets.reduce((prev, curr) =>
                curr.top < prev.top ? curr : prev
            );

            setActiveSection(closest.index);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [sections]);

    return { activeSection, setActiveSection, jumpToActiveSection };
};

export type { ScrollableSection, ScrollableSectionPosition };
export type { State as ScrollSpyState, UseState as UseScrollSpyState };
export { useScrollSpy };
