import { createFilterOptions } from '@mui/material';
import {
    Autocomplete,
    AutocompleteProps,
    TextField,
    TextFieldProps,
    Checkbox,
    // Chip,
    Paper,
} from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export interface Category<T> {
    id: string;
    parentId: string | null;
    name: string;
    children?: T[];
}

export const toCategory = <T extends Category<T>>(
    option: Option,
    depth: number = 0,
    parentId: string | null = null
): Category<T> => {
    return {
        id: option.id,
        name: option.name,
        parentId: option.parentId,
    };
};

export const hasRootCategory = <T extends Category<T>>(categories: Category<T>[]): boolean => {
    const rootCategory = categories.find((c) => !c.parentId);

    return rootCategory ? true : false;
};

export const getParentCategory = <T extends Category<T>>(
    childCategory: Category<T>,
    categories: Category<T>[]
): Category<T> | null | undefined => {
    let parentCategory: Category<T> | null | undefined = null;

    if (childCategory.parentId) {
        parentCategory = categories.find((c) => c.id === childCategory.parentId);
    }

    return parentCategory;
};

export const getChildCategories = <T extends Category<T>>(
    parentCategory: Category<T>,
    categories: Category<T>[]
): Category<T>[] | null | undefined => {
    return categories.filter((c) => c.parentId === parentCategory.id);
};

export function buildCategoryTree<T extends Category<T>>(categories: T[]): T[] {
    const categoryMap: { [key: string]: T } = {};
    const rootCategories: T[] = [];

    // Populate the category map
    categories.forEach((category) => {
        // Initialize children array for each category
        category.children = [];
        // Add the category to the map with its id as the key
        categoryMap[category.id] = category;
    });

    // Build the tree structure
    categories.forEach((category) => {
        if (category.parentId) {
            // If the category has a parentId, find the parent in the map
            const parentCategory = categoryMap[category.parentId];
            if (parentCategory) {
                // Add the current category to the parent's children array
                parentCategory.children!.push(category);
            }
        } else {
            // If the category doesn't have a parentId, it's a root category
            rootCategories.push(category);
        }
    });

    return rootCategories;
}

export interface Option {
    id: string;
    name: string;
    depth: number;
    parentId: string | null;
    matchTerms: string[];
}

export const toOptions = <T extends Category<T>>(
    category: Category<T>,
    depth: number = 0,
    parentId: string | null = null
): Option[] => {
    const { id, name, children = [] } = category;
    const grandchildren = children.flatMap((child) => toOptions(child, depth + 1, id));
    const option = {
        id,
        name,
        depth,
        parentId,
        matchTerms: [name].concat(grandchildren.map((obj) => obj.name)),
    };
    return [option].concat(grandchildren);
};

export type CategoryAutcompleteProps = {
    categories: Category<any>[];
    selectedCategories: Category<any>[];
    onSelectedCategories: (selectedCategories: Category<any>[], reason: string) => void;

    hideDepthOption?: boolean;
    error?: boolean;
    label?: string;
    required?: boolean;
    variant?: TextFieldProps['variant'];
    TextFieldProps?: TextFieldProps;
} & Omit<AutocompleteProps<Option, true, false, false>, 'renderInput' | 'options' | 'value' | 'onChange'>;

export default function CategoryAutocomplete(props: CategoryAutcompleteProps) {
    const {
        categories,
        selectedCategories,
        onSelectedCategories,
        hideDepthOption = false,
        error = false,
        ...autocompleteProps
    } = props;

    return (
        <Autocomplete
            multiple
            size="small"
            disableCloseOnSelect={true}
            options={buildCategoryTree(categories).flatMap((category) => toOptions(category))}
            value={
                selectedCategories
                    ? selectedCategories.map((pc) => ({
                          id: pc.id,
                          name: pc.name,
                          parentId: pc.parentId,
                          depth: 0,
                          matchTerms: [],
                      }))
                    : []
            }
            onChange={(event, options, reason) => {
                onSelectedCategories(
                    options.map((o) => toCategory(o)),
                    reason
                );
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option, { selected, inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);

                return (
                    <li
                        {...props}
                        key={option.id}
                        style={{ display: option.depth > 0 && hideDepthOption ? 'none' : 'flex' }}
                    >
                        <Checkbox checked={selected} sx={{ ml: 2 * option.depth }} />
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            renderInput={(inputProps) => (
                <TextField
                    error={error}
                    label={props.label ?? 'Category'}
                    variant={props.variant ?? 'outlined'}
                    required={props.required !== undefined ? props.required : !props.disabled}
                    {...inputProps}
                    {...props.TextFieldProps}
                />
            )}
            filterOptions={createFilterOptions({
                // Join with some arbitrary separator to prevent matches across adjacent terms
                stringify: (option) => option.matchTerms.join('//'),
            })}
            PaperComponent={(paperComponentProps) => (
                <Paper
                    {...paperComponentProps}
                    sx={{
                        boxShadow: 8,
                        borderRadius: '8px',
                    }}
                />
            )}
            {...autocompleteProps}
            sx={{
                '& .MuiSvgIcon-root': {
                    color: (theme) => theme.palette.common.black,
                },
                ...autocompleteProps.sx,
            }}
        />
    );
}
