import { Campaign } from '../../../types/Campaign';
import CDGrid, { CustomPagination } from '../../../components/CDGrid';
import { GridColumns, GridFooterContainer, GridRowClassNameParams, GridRowModel } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import AddNewAdServerPlacement from './AddNewAdServerPlacement';
import ApiService from '../../../ApiService';
import Utils from '../../../components/Utils';
import { AdServerPlacement } from '../../../types/FtCampaign';

interface AdServerPlacementGridFooterProps {
    dealerId: number;
    reloadCampaign(): void;
}

function AdServerPlacementGridFooter(props: AdServerPlacementGridFooterProps) {
    const { dealerId, reloadCampaign } = props;

    return (
        <GridFooterContainer>
            <AddNewAdServerPlacement dealerId={dealerId} reloadCampaign={reloadCampaign} />

            <CustomPagination />
        </GridFooterContainer>
    );
}

export default function AdServerPlacementsTab(props: { campaign: Campaign; reloadCampaign(): void }) {
    const { campaign, reloadCampaign } = props;

    function getCellClass(params: GridRowClassNameParams) {
        const now = new Date();
        if (now > params.row.startDateTime && now < params.row.endDateTime) {
            return 'currentAdServerPlacement';
        } else {
            return '';
        }
    }

    const handleDelete = async (ftPlacement: AdServerPlacement) => {
        const response = await ApiService.deleteAdServerPlacement(ftPlacement.id);
        if (response.status === 200) {
            reloadCampaign();
        }
        // reloadCampaign();
        // setPlacements(placements.filter(placement => placement.id !== id));
    };

    const columns: GridColumns = [
        { field: 'fileNameKey', headerName: 'Filename Key', minWidth: 225, flex: 1 },
        { field: 'adServerCampaignName', headerName: 'Campaign Name', flex: 1 },
        { field: 'placementName', headerName: 'Placement Name', flex: 1 },
        { field: 'placementId', headerName: 'Placement ID', width: 150 },
        { field: 'fileName', headerName: 'Last Creative Name', minWidth: 500, flex: 1 },
        { field: 'status', headerName: 'Status', width: 200 },
        {
            field: 'createdDate',
            headerName: 'Created Date',
            valueGetter: (params) => Utils.formatDateTime(params.value),
            width: 200,
        },
        {
            field: 'updatedDate',
            headerName: 'Updated Date',
            valueGetter: (params) => Utils.formatDateTime(params.value),
            width: 200,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            renderCell: (rowData: any) => <Button onClick={() => handleDelete(rowData.row)}>Disconnect</Button>,
        },
    ];

    return (
        <>
            <Box
                sx={{
                    '& .currentAdServerPlacement': {
                        fontWeight: 600,
                    },
                    width: '100%',
                }}
            >
                <CDGrid
                    columns={columns}
                    rows={campaign.adServerPlacements}
                    getRowClassName={getCellClass}
                    processRowUpdate={(row: GridRowModel) => {
                        // ApiService.updateAdServerPlacement(row as CampaignAdServerPlacement);
                        return row;
                    }}
                    onProcessRowUpdateError={() => {}}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        Footer: AdServerPlacementGridFooter,
                    }}
                    componentsProps={{
                        footer: {
                            dealerId: campaign.dealerId,
                            reloadCampaign,
                        },
                    }}
                />
            </Box>
        </>
    );
}
