import { useEffect, useMemo, useState } from 'react';
import { Grid, RadioGroup, SvgIconProps } from '@mui/material';
import { AutoAwesomeOutlined as AutoAwesomeOutlinedIcon } from '@mui/icons-material';

import { Code } from '../../../types/Code';
import { RadioCard, RadioCardGroupProps } from '../../../components/RadioCard';
import { useCodeList } from '../../../hooks/Models/CodeList';

type CampaignAudienceTypeRadioGroupProps = {
    filter?(codes: Code[]): Code[];
} & RadioCardGroupProps;

export default function CampaignAudienceTypeRadioGroup(props: CampaignAudienceTypeRadioGroupProps) {
    const { disabled, filter, ...radioGroupProps } = props;
    const { codes, defaultCode } = useCodeList({ codeGroup: 'AudienceType' });
    const [filteredCodes, setFilteredCodes] = useState<Code[]>([]);

    const defaultValue = useMemo(() => {
        return defaultCode ? defaultCode.code : '';
    }, [defaultCode]);

    useEffect(() => {
        if (codes && codes.length > 0) {
            setFilteredCodes(filter ? filter(codes) : codes);
        } else {
            setFilteredCodes([]);
        }
    }, [codes, filter]);

    const getCodeIcon = (code: Code): React.ComponentType<SvgIconProps> | undefined => {
        switch (code.code) {
            case 'AI':
                return AutoAwesomeOutlinedIcon;

            case 'CUSTOM':
                return undefined;
        }

        return undefined;
    };

    const getCodeLabel = (code: Code): string => {
        switch (code.code) {
            case 'AI':
                return 'AI Audience';

            case 'CUSTOM':
                return 'Custom Audience';
        }

        return code.description;
    };

    const getCodeDescription = (code: Code): string => {
        switch (code.code) {
            case 'AI':
                return 'Automatically curates relevant audience segments using advanced algorithms for optimal targeting.';

            case 'CUSTOM':
                return 'Craft a tailored audience by selecting specific segments.';
        }

        return code.description;
    };

    return (
        <RadioGroup defaultValue={defaultValue} {...radioGroupProps} value={props.value}>
            {filteredCodes && (
                <Grid container spacing={3}>
                    {filteredCodes.map((code: Code) => {
                        return (
                            <Grid key={code.code} item xs={6}>
                                <RadioCard
                                    direction="row"
                                    required={true}
                                    disabled={disabled}
                                    value={code.code}
                                    description={getCodeDescription(code)}
                                    label={getCodeLabel(code)}
                                    LabelIcon={getCodeIcon(code)}
                                    CardProps={{
                                        sx: {
                                            '& .MuiCardContent-root': {
                                                minHeight: 140,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </RadioGroup>
    );
}
