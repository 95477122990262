import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { AxiosPageableResponse } from '../types/AxiosPageableResponse';

type Props = {
    label: string;
    required?: boolean;
    parentId: number | null;
    value?: any;
    getOptionLabel?: (option: any) => string;
    onChange: (val: any) => void;
    handleFetch: (
        page: number,
        name: string,
        parentId: number | null,
        size?: number
    ) => Promise<AxiosPageableResponse<any>>;
    [key: string]: any;
};

export default function CDAutocompleteEndless(props: Props) {
    const { label, required, parentId, value, getOptionLabel, onChange, handleFetch, ...rest } = props;
    const [options, setOptions] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [searchText, setSearchText] = useState('');
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (page === undefined) return;
        setLoading(true);
        handleFetch(page, searchText, parentId)
            .then((response) => {
                setOptions((prevOptions) => [...(page === 0 ? [] : prevOptions), ...response.data.content]);
                setTotal(response.data.totalElements);
            })
            .finally(() => setLoading(false));
    }, [page, searchText, parentId]);

    const debounce = (func: (...args: any[]) => any, delay: number) => {
        let timeoutId: any;
        return (...args: any[]) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedChangeSearchCriteria = useRef(
        debounce((val) => {
            if (val === undefined) return;
            setPage(0);
            setSearchText(val);
        }, 1000)
    ).current;

    const handleInputChange = (event: React.ChangeEvent<{}>, val: string) => {
        if (searchText === val) return;
        debouncedChangeSearchCriteria(val);
    };

    const handleOptionChange = (event: React.ChangeEvent<{}>, val: any) => {
        onChange(val);
        debouncedChangeSearchCriteria(undefined);
    };

    const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
        if (loading) return;
        const listboxNode = event.currentTarget;
        const tolerance = 5;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - tolerance) {
            if (options.length < total) {
                setPage((prevPage) => (prevPage || 0) + 1);
            }
        }
    };

    return (
        <Autocomplete
            {...rest}
            options={options}
            getOptionLabel={getOptionLabel}
            loading={loading}
            onInputChange={handleInputChange}
            value={value}
            onChange={handleOptionChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    required={required}
                    size="small"
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            ListboxProps={{ onScroll: handleScroll }}
        />
    );
}
