import { useCallback, useEffect, useReducer } from 'react';
import { CpmValue } from '../../types/Campaign';
import { useCampaignCPMValueListQuery } from '../Queries/CampaignCPMValueListQuery';

const ACTION_TYPES = {
    SET_STATE: 'SET_STATE',
};

type ActionType = keyof typeof ACTION_TYPES;

interface State {
    dealerId: number;
    cpmValues: CpmValue[];
}

interface Action {
    type: ActionType | string;
    payload?: Partial<State>;
}

interface UseState extends State {
    fetchCpmValues: () => void;
    setCpmValues: (cpmValues: CpmValue[]) => void;
    getCpmValue: (mediaType: string) => any;
    isFetchingCpmValues: boolean;
    isLoadingCpmValues: boolean;
}

interface UseStateProps {
    dealerId: number;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATE:
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

const initialState: State = {
    dealerId: 0,
    cpmValues: [],
};

const useCampaignCPMValueList = (props: UseStateProps): UseState => {
    const { dealerId } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const { data, refetch, isFetching, isLoading } = useCampaignCPMValueListQuery(dealerId);

    const setDealerId = (dealerId: number): void => {
        dispatch({ type: ACTION_TYPES.SET_STATE, payload: { dealerId } });
    };

    const setCpmValues = (cpmValues: CpmValue[]): void => {
        dispatch({ type: ACTION_TYPES.SET_STATE, payload: { cpmValues } });
    };

    const getCpmValue = (mediaType: string): CpmValue => {
        let cpmValue: CpmValue | undefined;

        if (state.cpmValues && state.cpmValues.length > 0) {
            cpmValue = state.cpmValues.find((o: CpmValue) => o.mediaType === mediaType.replace(' ', ''));
        }

        return (
            cpmValue || {
                mediaType: '',
                cpmValue: 0,
                fixedCpm: false,
            }
        );
    };

    const fetchCpmValues = useCallback((): void => {
        if (!data) {
            refetch();
        }
    }, [data, refetch]);

    useEffect(() => {
        setDealerId(dealerId);
    }, [dealerId]);

    useEffect(() => {
        setCpmValues(data || []);
    }, [data]);

    return {
        ...state,

        isFetchingCpmValues: isFetching,
        isLoadingCpmValues: isLoading,

        fetchCpmValues,
        setCpmValues,
        getCpmValue,
    };
};

export { ACTION_TYPES as CpmValueListActionTypes };
export type {
    ActionType as CpmValueListActionType,
    Action as CpmValueListAction,
    State as CpmValueListState,
    UseState as UseCpmValueListState,
};
export { useCampaignCPMValueList };
