import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async (campaignId: number, campaignChangeRequestId?: number) => {
    const { data } = await ApiService.getCampaignCreatives(campaignId, campaignChangeRequestId);
    return data;
};

const useCampaignCreativeListQuery = (campaignId: number, campaignChangeRequestId?: number) => {
    return useQuery(
        ['campaignCreativeList', campaignId, campaignChangeRequestId],
        () => fetchData(campaignId, campaignChangeRequestId),
        {
            staleTime: Infinity,
            enabled: !!campaignId,
        }
    );
};

export { useCampaignCreativeListQuery };
