import { ChangeEvent } from 'react';
import { FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { Code } from '../../../../types/Code';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import Column from '../../../../components/Column';
import CampaignMediaTypeRadioGroup from '../CampaignMediaTypeRadioGroup';
import CampaignStrategyTypeRadioGroup from '../CampaignStrategyTypeRadioGroup';

type CampaignSetupPlanCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupPlanCard(props: CampaignSetupPlanCardProps) {
    const { campaign } = props;
    const { $campaignDefaultSettings, generateCampaignName, hasError, isDraftCampaign, isNewCampaign } =
        useCampaignWizardContext();
    const { campaignDefaultSettingCriteria = {}, setCampaignDefaultSettingCriteria } = $campaignDefaultSettings || {};

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            switch (event.target.name) {
                case 'mediaType':
                    campaign.mediaType = event.target.value;

                    if (setCampaignDefaultSettingCriteria && campaignDefaultSettingCriteria) {
                        setCampaignDefaultSettingCriteria({
                            ...campaignDefaultSettingCriteria,
                            isDirty: true,
                            mediaType: event.target.value as string,
                            goalType: campaign.goal as string,
                        });
                    }

                    props.onChange({
                        [event.target.name]: event.target.value,
                        campaignName: generateCampaignName(campaign),
                    });
                    break;

                default:
                    props.onChange({
                        [event.target.name]: event.target.value,
                    });
                    break;
            }
        }
    };

    return (
        <Card id="campaign-wizard-setup-plan" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Media Type & Strategy
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <FormControl error={hasError('mediaType')}>
                        <FormLabel required={true} sx={{ mb: 2 }}>
                            Select a Media Type
                        </FormLabel>

                        <CampaignMediaTypeRadioGroup
                            name="mediaType"
                            value={campaign.mediaType}
                            onChange={handleChange}
                            disabled={!isNewCampaign && !isDraftCampaign}
                            filter={(codes: Code[]): Code[] => {
                                const filteredCodes: Code[] = [];

                                ['Display', 'Online Video', 'Streaming TV', 'Audio'].forEach((codeCode: string) => {
                                    const code: Code | undefined = codes.find((o: Code) => o.code === codeCode);

                                    if (code) {
                                        filteredCodes.push(code);
                                    }
                                });

                                return filteredCodes;
                            }}
                        />

                        {hasError('mediaType') && (
                            <FormHelperText error={true}>Media Type selection is required</FormHelperText>
                        )}
                    </FormControl>

                    <FormControl error={hasError('strategyType')}>
                        <FormLabel required={true} sx={{ mb: 2 }}>
                            Select a Campaign Strategy
                        </FormLabel>

                        <CampaignStrategyTypeRadioGroup
                            name="strategyType"
                            value={campaign.strategyType}
                            onChange={handleChange}
                            disabled={!isNewCampaign && !isDraftCampaign}
                            filter={(codes) => {
                                if (campaign.dealer?.verticalId && campaign.dealer.verticalId !== 2) {
                                    codes = codes.filter((code: Code) => code.code === 'Awareness');
                                } else {
                                    codes = codes.filter((code: Code) => code.code !== 'Parts');
                                }
                                return codes;
                            }}
                        />

                        {hasError('strategyType') && (
                            <FormHelperText error={true}>Campaign Strategy selection is required</FormHelperText>
                        )}
                    </FormControl>
                </Column>
            </CardContent>
        </Card>
    );
}
