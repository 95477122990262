export class AudienceSource {
    audienceId: string = '';
    audienceName: string = '';
    description: string = '';
    category: string = '';
    subCategory: string = '';
    status: string = '';
    providerId: string = '';
    forecasts: any = {};
    fees: AudienceSourceFee[] = [];
}

export interface AudienceSourceCriteria {
    [key: string]: any;
    mediaType: string | undefined;
    searchText: string;
    categories: string[];
    costType?: string;
    page?: number;
    pageSize?: number;
    sort?: string;
}

export interface AudienceSourceInventoryForecast {
    lowerBoundInclusive: number;
    upperBoundExclusive: number;
}

export interface AudienceSourceFee {
    amount: number;
    currency: string;
    feeCalculationType: string;
    impressionSupplyType: string;
    scale: number;
}
