import { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { Dealer } from '../../../../types/Dealer';
import { Location } from '../../../../types/Location';
import { ZipCode } from '../../../../types/Location';
import { toCampaignAdGroupLocations } from '../../../../hooks/useLocations';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { useLocationBoundaries } from '../../../../hooks/useMapBoundaries';
import { useMapToken } from '../../../../hooks/Models/MapToken';
import LocationSourceField from '../../../../components/Location/LocationSourceField';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignTargetingLocationCardProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingLocationCard(props: CampaignTargetingLocationCardProps) {
    const { campaign, onChange } = props;
    const { dealer } = campaign;

    const country = 'United States';
    const countryCode = 'US';
    const baseUrl = '/api/geographicTargeting';

    const { mapToken } = useMapToken();

    const $campaignWizard = useCampaignWizardContext();
    const { hasError } = $campaignWizard;
    const { isCompletedCampaign } = $campaignWizard;

    const { $locations, $loader } = $campaignWizard;
    const {
        locations: selections = [],
        fetchLocations: fetchSelections = () => {},
        isFetchingLocations: isFetchingSelections = false,
        hydrateLocations: hydrateSelections = (selections: Location[]) => [],
        getLocationByRawZipCode = (rawZipCode: string) => undefined,
    } = $locations || {};

    const getCenterPoint = useCallback(
        async (country: string, zipCode: string) => {
            if (!mapToken) {
                return { center: [-97.9222112121185, 39.3812661305678] };
            }

            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipCode}.json?country=${countryCode}&limit=1&proximity=ip&access_token=${mapToken}`
            );
            const data = await response.json();

            if (data.features.length > 0) {
                return { center: data.features[0].center };
            }

            // USA
            return { center: [-97.9222112121185, 39.3812661305678] };
        },
        [mapToken]
    );

    const $locationBoundaries = useLocationBoundaries({
        baseUrl: baseUrl,
        country: country,
        getCenterPoint: getCenterPoint,
    });

    const [locations, setLocations] = useState<Location[]>([]);

    const [centerPoint, setCenterPoint] = useState('');
    const [mapCenter, setMapCenter] = useState('');

    function getAddress(dealer: Dealer, zipcodes?: string): string {
        let address = '';
        if (dealer) {
            if (dealer.address) address = dealer.address;
            if (dealer.city) address += ' ' + dealer.city;
            if (dealer.state) address += ' ' + dealer.state;
            if (dealer.zip) address += ' ' + dealer.zip;
        }
        if (address === '' && zipcodes && zipcodes.length > 0) {
            address = zipcodes.split(',')[0];
        }
        return address;
    }

    const handleRecenterLocation = useCallback((): void => {
        const lastLocation: Location | undefined = locations?.[locations.length - 1];
        const rawCenterPoint: string = lastLocation?.name ?? '';

        setMapCenter(rawCenterPoint ? rawCenterPoint : centerPoint);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, onChange]);

    const assignDefaultAdvertiserLocation = useCallback(() => {
        if (dealer) {
            const defaultAdvertiserLocation: Location | undefined = getLocationByRawZipCode(dealer.zip);

            if (defaultAdvertiserLocation) {
                handleLocationChange([defaultAdvertiserLocation]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, selections]);

    useEffect(() => {
        let rawCenterPoint = '';

        if (locations.length > 0) {
            const lastLocation: Location | undefined = locations?.[locations.length - 1];
            rawCenterPoint = lastLocation?.name ?? '';
        }

        if (!centerPoint.length) {
            if (dealer) {
                rawCenterPoint = getAddress(dealer, campaign.zipcodes);
            }

            setCenterPoint(rawCenterPoint);
            setMapCenter(rawCenterPoint);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealer, campaign.zipcodes, locations]);

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchLocationSources');
            } else {
                $loader.loaded('fetchLocationSources');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if ($locations) {
                const newLocations: Location[] = $locations.getCampaignLocations(campaign);

                if (newLocations.length) {
                    setLocations(newLocations);
                } else {
                    assignDefaultAdvertiserLocation();
                }
            }
        } else {
            setLocations([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, assignDefaultAdvertiserLocation]);

    const updateLocations = useCallback(
        async (newLocations: Location[]) => {
            setLocations(newLocations);

            if (onChange) {
                if (campaign?.adGroups?.length) {
                    campaign.adGroups[0] = {
                        ...campaign.adGroups[0],
                        adGroupLocations: toCampaignAdGroupLocations(
                            newLocations,
                            campaign.adGroups[0].adGroupLocations ?? []
                        ),
                    };
                } else {
                    campaign.adGroups.push({
                        adGroupLocations: toCampaignAdGroupLocations(newLocations),
                    });
                }

                onChange({
                    adGroups: campaign.adGroups,
                });
            }

            if (onChange) {
                const zipcodes: string[] = [];

                if (newLocations.length) {
                    if ($campaignWizard.$loader) {
                        $campaignWizard.$loader.loading('campaignAttachLocations');
                    }

                    for (const newLocation of newLocations) {
                        const newZipCodes: ZipCode[] = await $locationBoundaries.getLocationBoundaryZipCodes(
                            newLocation,
                            campaign.zipcodeRadius
                        );

                        if (newZipCodes.length) {
                            zipcodes.push(...newZipCodes.map((z: ZipCode) => z.code));
                        }
                    }

                    if ($campaignWizard.$loader) {
                        $campaignWizard.$loader.loaded('campaignAttachLocations');
                    }
                }

                onChange({
                    zipcodes: zipcodes.join(','),
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange, campaign]
    );

    const handleLocationChange = async (newLocations: Location[]): Promise<void> => {
        await updateLocations([...newLocations]);
    };

    const handleRadiusChange = (newRadius: number): void => {
        onChange({
            zipcodeRadius: newRadius,
        });
    };

    useEffect(() => {
        handleRecenterLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    return (
        <Card id="campaign-wizard-targeting-location" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Location
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Row>
                    <FormControl>
                        <Column gap={2}>
                            <FormLabel required={true}>Select Locations to Target Your Audience</FormLabel>

                            <Box>
                                <LocationSourceField
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    hydrateSelections={hydrateSelections}
                                    onChange={handleLocationChange}
                                    items={locations}
                                    radius={campaign.zipcodeRadius}
                                    disableRadius={isCompletedCampaign}
                                    onRadiusChange={handleRadiusChange}
                                    mapCenter={mapCenter}
                                />
                            </Box>
                        </Column>

                        {hasError('adGroups.*.adGroupLocations') && (
                            <FormHelperText error={true} sx={{ ml: 7 }}>
                                Location(s) selection is required. Please select at least one location.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Row>
            </CardContent>
        </Card>
    );
}
