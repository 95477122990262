import { useEffect, useState } from 'react';
import {
    // Table,
    TableBody,
    // TableCell,
    // TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

import { Campaign } from '../../../types/Campaign';
import { CapitalizeTypography } from './CampaignWizard/CampaignWizardStyles';
import { InventorySource } from '../../../types/Inventory';
import { ProductCategory } from '../../../components/ProductCategoryAutocomplete';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import { useCodeList } from '../../../hooks/Models/CodeList';
import { TableCell, TablePrimaryCell } from './CampaignWizard/CampaignWizardStyles';
import { Table } from '../../../components/Table';
import Column from '../../../components/Column';
import InventorySourceField from '../../../components/Inventory/InventorySourceField';
import Utils from '../../../components/Utils';

type CampaignSummarySetupTableProps = {
    campaign: Campaign;
};

export default function CampaignSummarySetupTable(props: CampaignSummarySetupTableProps) {
    const { campaign } = props;
    const { campaignBidStrategyLabel, campaignBudgetManagementStrategyLabel } = useCampaignWizardContext();
    const { getCodeDescription: getGoalTypeCodeDescription } = useCodeList({ codeGroup: 'GoalType' });
    const { getCodeDescription: getKpiTypeCodeDescription } = useCodeList({ codeGroup: 'KpiType' });
    const { getCodeDescription: getMediaTypeCodeDescription } = useCodeList({ codeGroup: 'MediaType' });
    const { getCodeDescription: getStrategyTypeCodeDescription } = useCodeList({ codeGroup: 'StrategyType' });

    const { $productCategories } = useCampaignWizardContext();
    const { getCampaignProductCategory = (campaign: Campaign): ProductCategory | null => null } =
        $productCategories || {};
    const productCategory: ProductCategory | null = getCampaignProductCategory(campaign);

    const { $inventorySources } = useCampaignWizardContext();
    const { inventorySources: inventorySourceSelections = [], getCampaignInventorySources = undefined } =
        $inventorySources || {};
    const [inventorySources, setInventorySources] = useState<InventorySource[]>([]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (getCampaignInventorySources) {
                setInventorySources(getCampaignInventorySources(campaign));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, inventorySourceSelections]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>Campaign Setup</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow>
                    <TablePrimaryCell>Advertiser Name</TablePrimaryCell>
                    <TableCell>{campaign?.dealer?.dealerName}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Product Category</TablePrimaryCell>
                    <TableCell>{productCategory?.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Media Type</TablePrimaryCell>
                    <TableCell>{getMediaTypeCodeDescription(campaign.mediaType)}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Campaign Strategy</TablePrimaryCell>
                    <TableCell>{getStrategyTypeCodeDescription(campaign.strategyType)}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Start & End Date</TablePrimaryCell>
                    <TableCell>{`${Utils.formatESTDateLong(campaign.startDate)} - ${Utils.formatESTDateLong(
                        campaign.endDate
                    )}`}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Budget</TablePrimaryCell>
                    <TableCell>{Utils.formatCurrency(campaign.budget)}</TableCell>
                </TableRow>
                <TableRow>
                    <TablePrimaryCell>Budget Cap</TablePrimaryCell>
                    <TableCell>
                        {campaign.budgetCapType !== 'CUSTOM' && (
                            <CapitalizeTypography>{Utils.convertCase(campaign.budgetCapType)}</CapitalizeTypography>
                        )}
                        {campaign.budgetCapType === 'CUSTOM' && (
                            <>
                                <CapitalizeTypography component="span">
                                    {Utils.convertCase(campaign.budgetCapRecurrenceTimePeriod)}
                                </CapitalizeTypography>
                                {', '}
                                <Typography component="span" variant="body2">
                                    {Utils.formatCurrency(campaign.budgetCapAmount, 2)}
                                </Typography>
                            </>
                        )}
                    </TableCell>
                </TableRow>
                {false && (
                    <TableRow>
                        <TablePrimaryCell>Campaign Goal</TablePrimaryCell>
                        <TableCell>{getGoalTypeCodeDescription(campaign.goal)}</TableCell>
                    </TableRow>
                )}
                {false && (
                    <TableRow>
                        <TablePrimaryCell>KPI</TablePrimaryCell>
                        <TableCell>{getKpiTypeCodeDescription(campaign.kpi)}</TableCell>
                    </TableRow>
                )}
                {false && (
                    <TableRow>
                        <TablePrimaryCell>KPI Target</TablePrimaryCell>
                        <TableCell>
                            {campaign.targetKpiDisabled ? (
                                <>Not set</>
                            ) : (
                                <>{Utils.formatValue(campaign.targetKpi, 'percent')}</>
                            )}
                        </TableCell>
                    </TableRow>
                )}
                {false && (
                    <TableRow>
                        <TablePrimaryCell>Bidding Priority</TablePrimaryCell>
                        <TableCell>{campaignBidStrategyLabel ?? '-'}</TableCell>
                    </TableRow>
                )}
                {false && (
                    <TableRow>
                        <TablePrimaryCell>Budget Management Strategy</TablePrimaryCell>
                        <TableCell>{campaignBudgetManagementStrategyLabel ?? '-'}</TableCell>
                    </TableRow>
                )}
                {false && (
                    <TableRow>
                        <TablePrimaryCell>Inventory Source</TablePrimaryCell>
                        <TableCell>
                            <Column>
                                <InventorySourceField items={inventorySources} />
                            </Column>
                        </TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TablePrimaryCell>Frequency</TablePrimaryCell>
                    <TableCell>
                        <Column gap={0}>
                            Frequency cap
                            <Typography variant="caption" color="text.secondary">
                                Show no more than {campaign.frequencyMaxImpressions} times per{' '}
                                {campaign.frequencyTimeUnitCount} {campaign?.frequencyTimeUnit?.toLowerCase()}
                            </Typography>
                        </Column>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
