import { useCallback, useEffect, useState } from 'react';
import { useMapTokenQuery } from '../Queries/MapTokenQuery';

interface State {
    mapToken: string | null;
}

interface UseState extends State {
    fetchMapToken: () => void;
    setMapToken: (mapToken: string | null) => void;
    isFetchingMapToken: boolean;
    isLoadingMapToken: boolean;
}

interface UseStateProps {}

const initialState: State = {
    mapToken: null,
};

const useMapToken = (props?: UseStateProps): UseState => {
    const [mapToken, setMapToken] = useState<string | null>(initialState.mapToken);
    const { data, refetch, isFetching, isLoading } = useMapTokenQuery();

    const fetchMapToken = useCallback((): void => {
        if (!data) {
            refetch();
        }
    }, [data, refetch]);

    useEffect(() => {
        setMapToken(data || []);
    }, [data]);

    return {
        isFetchingMapToken: isFetching,
        isLoadingMapToken: isLoading,

        fetchMapToken,
        setMapToken,
        mapToken: data ? data : mapToken,
    };
};

export type { State as MapTokenState, UseState as UseMapTokenState };
export { useMapToken };
