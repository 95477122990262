import { useEffect, useState } from 'react';
import CDButton from '../../../components/CDButton';
import {
    AlertColor,
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
} from '@mui/material';
import Row from '../../../components/Row';
import { CDTextField } from '../../../components/CDTextField';
import { useParams } from 'react-router-dom';
import ApiService from '../../../ApiService';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';
import {
    AdServerImportSource,
    AdServerPlacement,
    AdServerPlacementStatus,
    FtCampaign,
    FtPlacement,
} from '../../../types/FtCampaign';
import { FileInfo } from '../../../types/S3';
import AppLoader from '../../../components/AppLoader';

const ADSERVER_PLACEMENT_LIBRARY_ID = 205331;

const initialValues = {
    fileNameKey: null,
    libraryId: null,
    adServerCampaignId: null,
    adServerCampaignName: null,
    placementId: null,
    placementName: null,
    fileName: null,
};
function validate(
    data: typeof initialValues,
    getErrors: (value: React.SetStateAction<{ fileNameKey: string; placementId: string }>) => void
) {
    const newErrors: any = {};
    if (!data.placementId) {
        newErrors.placementId = 'Placement is required';
    }

    getErrors(newErrors);
    return Object.keys(newErrors).length === 0;
}

type Props = {
    dealerId: number;
    reloadCampaign(): void;
};

export default function AddNewAdServerPlacement({ dealerId, reloadCampaign }: Props) {
    const { id } = useParams();
    const [ftCampaigns, setFtCampaigns] = useState<FtCampaign[]>([]);
    const [sources, setSources] = useState<AdServerImportSource[]>([]);
    const [importSourceId, setImportSourceId] = useState<number>(0);
    const [videoFiles, setVideoFiles] = useState<FileInfo[] | null>(null);
    const [ftPlacements, setFtPlacements] = useState<FtPlacement[] | null>(null);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState(initialValues);

    const [errors, setErrors] = useState({ fileNameKey: '', placementId: '' });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isOpen || ftCampaigns.length) return;
        ApiService.getAdServerCampaigns(dealerId)
            .then((response) => {
                setFtCampaigns(response.data);
            })
            .catch(() => {});

        ApiService.getAdServerImportSources()
            .then((response) => {
                setSources(response.data);
            })
            .catch(() => {});
    }, [isOpen]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const placementName: any =
            name == 'placementId' ? ftPlacements?.find((p) => p.id === +value)?.name : formData.placementName;
        setErrors((prev) => ({ ...prev, [name]: '' }));
        setFormData((prev) => ({ ...prev, placementName, [name]: value }));
    };

    const handleSourceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const sourceId: any = +event.target.value;
        setImportSourceId(sourceId);
        setVideoFiles(null);
        setFormData((prev) => ({ ...prev, fileNameKey: null, fileName: null }));
        ApiService.getAdServerIncomingS3Files(sourceId, '.mp4')
            .then((response) => {
                setVideoFiles(response.data);
            })
            .catch(() => {});
    };

    const handleVideoFileChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        const fileNameKey: any = value?.split('_')[0] || '';
        const fileName: any = value || '';
        setFormData((prev) => ({ ...prev, fileNameKey, fileName }));
    };

    const handleFtCampaignChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFtPlacements(null);
        const adServerCampaignId: any = +event.target.value;
        ApiService.getAdServerPlacements(dealerId, adServerCampaignId)
            .then((response) => {
                setFtPlacements(response.data);
                let err = '';
                if (response.data.length === 0) err = 'No placements available';
                setErrors((prev) => ({ ...prev, placementId: err }));
                const adServerCampaignName: any = ftCampaigns?.find((c) => c.id === adServerCampaignId)?.name;
                setFormData((prev) => ({
                    ...prev,
                    adServerCampaignId,
                    adServerCampaignName,
                    placementName: null,
                    placementId: null,
                }));
            })
            .catch(() => {});
    };

    const clearFormData = () => {
        setFtCampaigns([]);
        setFtPlacements(null);
        setFormData(initialValues);
    };

    const handleDialogToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleDialogClose = () => {
        handleDialogToggle();
        setErrors({ fileNameKey: '', placementId: '' });
        clearFormData();
    };

    function showInfoMessage(alertColor: AlertColor, message: string, onClose?: () => void) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
                if (onClose) onClose();
            },
        });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validate(formData, setErrors)) return;
        const { fileNameKey, adServerCampaignId, adServerCampaignName, placementId, placementName, fileName } =
            formData;
        if (
            !id ||
            !fileNameKey ||
            !adServerCampaignId ||
            !adServerCampaignName ||
            !placementId ||
            !placementName ||
            !fileName
        )
            return;
        const libraryId = ADSERVER_PLACEMENT_LIBRARY_ID;
        const preparedData: Pick<
            AdServerPlacement,
            | 'fileNameKey'
            | 'libraryId'
            | 'adServerCampaignId'
            | 'adServerCampaignName'
            | 'placementId'
            | 'placementName'
            | 'fileName'
        > = {
            fileNameKey,
            libraryId,
            adServerCampaignId,
            adServerCampaignName,
            placementId,
            placementName,
            fileName,
        };

        setIsLoading(true);
        try {
            showInfoMessage('success', 'CREATING');
            let response = await ApiService.createAdServerPlacement(+id, preparedData);
            const adServerPlacementId = response.data.id;
            showInfoMessage('success', AdServerPlacementStatus.UPLOADING);
            try {
                response = await ApiService.uploadAdServerCreative(importSourceId, adServerPlacementId);
                let status = response.data.status;
                showInfoMessage('success', status);
                while (status !== AdServerPlacementStatus.APPROVED) {
                    // wait 30 seconds before checking again
                    await new Promise((resolve) => setTimeout(resolve, 10000));
                    showInfoMessage('success', 'TRYING TO APPROVE');
                    response = await ApiService.tryToApproveCreative(adServerPlacementId);
                    status = response.data.status;
                    showInfoMessage('success', status);
                }
                showInfoMessage('success', 'REPLACING');
                response = await ApiService.replacePlacementCreative(adServerPlacementId);
                status = response.data.status;
                showInfoMessage('success', status);
                if (status === AdServerPlacementStatus.REPLACED) {
                    showInfoMessage('success', 'Placement connected successfully');
                }
            } catch (error) {
                console.log(error);
                setInfoMessage({ message: null });
                await new Promise((resolve) => setTimeout(resolve, 5000));
            } finally {
                clearFormData();
                handleDialogToggle();
                reloadCampaign();
            }
        } catch (error) {
            setInfoMessage({ message: null });
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <InfoMessage {...infoMessage} />
            <CDButton sx={{ marginLeft: '10px' }} onClick={handleDialogToggle}>
                Connect Ad Server Placement
            </CDButton>
            <span></span> {/* <-- remove span when uncommenting the Add Flight button */}
            <Dialog open={isOpen} maxWidth="sm" fullWidth>
                <AppLoader loading={isLoading} />

                <DialogTitle>Connect Ad Server Placement</DialogTitle>

                <form onSubmit={handleSubmit}>
                    <DialogContent sx={{ padding: '10px' }}>
                        <Row sx={{ padding: '10px' }}>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                label="Select Source"
                                select
                                onChange={handleSourceChange}
                                SelectProps={{ native: false }}
                            >
                                {sources.map((source) => (
                                    <MenuItem key={source.id} value={source.id}>
                                        {source.s3Bucket}/{source.s3IncomingPath}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Row>

                        {videoFiles && (
                            <Autocomplete
                                sx={{ padding: '10px' }}
                                options={videoFiles.map((file) => file.name)}
                                onChange={handleVideoFileChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        required
                                        size="small"
                                        label="Select File"
                                        name="fileName"
                                    />
                                )}
                            />
                        )}

                        <Row sx={{ padding: '10px' }}>
                            <CDTextField
                                fullWidth
                                required
                                name="fileNameKey"
                                label="Filename Key"
                                variant="outlined"
                                value={formData.fileNameKey}
                                onChange={handleChange}
                                error={!!errors.fileNameKey}
                                helperText={errors.fileNameKey}
                            />
                        </Row>

                        <Row sx={{ padding: '10px' }}>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                label="Select Campaign"
                                name="selectedCampaign"
                                select
                                onChange={handleFtCampaignChange}
                                SelectProps={{ native: false }}
                            >
                                {ftCampaigns.map((campaign) => (
                                    <MenuItem key={campaign.id} value={campaign.id}>
                                        {campaign.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Row>

                        {ftPlacements && (
                            <Row sx={{ padding: '10px' }}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Select Placement"
                                    name="placementId"
                                    value={formData.placementId}
                                    error={!!errors.placementId}
                                    helperText={errors.placementId}
                                    select
                                    onChange={handleChange}
                                    SelectProps={{ native: false }}
                                >
                                    {ftPlacements.map((placement) => (
                                        <MenuItem key={placement.id} value={placement.id}>
                                            {placement.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Row>
                        )}
                    </DialogContent>

                    <DialogActions sx={{ padding: '10px 20px' }}>
                        <CDButton color="error" onClick={handleDialogClose}>
                            Cancel
                        </CDButton>

                        <CDButton type="submit" disabled={isLoading}>
                            Connect Placement
                        </CDButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
