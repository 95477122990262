import { ChangeEvent } from 'react';
import { FormControl, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel, FormControlLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { CDTextField } from '../../../../components/CDTextField';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import Column from '../../../../components/Column';
import FormLabelAsterisk from '../../../../components/FormLabelAsterisk';
import Row from '../../../../components/Row';

type CampaignSetupFrequencyCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupFrequencyCard(props: CampaignSetupFrequencyCardProps) {
    const { campaign } = props;
    const { hasError } = useCampaignWizardContext();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            switch (event.target.name) {
                case 'frequencyType':
                    if (event.target.value === 'CUSTOM') {
                        props.onChange({
                            [event.target.name]: event.target.value,
                            frequencyTimeUnit: 'DAYS',
                        });
                    } else {
                        props.onChange({
                            [event.target.name]: event.target.value,
                        });
                    }
                    break;

                default:
                    props.onChange({
                        [event.target.name]: event.target.value,
                    });
            }
        }
    };

    return (
        <Card id="campaign-wizard-setup-frequency" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Exposure Frequency
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <FormControl error={hasError('frequencyType')}>
                        <FormLabel required={true} sx={{ mb: 2 }}>
                            Select a Frequency
                        </FormLabel>

                        <RadioGroup name="frequencyType" value={campaign.frequencyType ?? ''} onChange={handleChange}>
                            <FormControlLabel value="UNCAPPED" control={<Radio />} label="Uncapped" />

                            <FormControlLabel value="CUSTOM" control={<Radio />} label="Set a frequency cap" />
                        </RadioGroup>
                    </FormControl>

                    {campaign.frequencyType === 'CUSTOM' && (
                        <FormControl>
                            <Row gap={1} sx={{ ml: 4, mt: -3 }} alignItems="center">
                                <Typography variant="caption" color="text.secondary">
                                    Show no more than <FormLabelAsterisk />
                                </Typography>{' '}
                                <CDTextField
                                    variant="outlined"
                                    type="number"
                                    name="frequencyMaxImpressions"
                                    required={campaign.frequencyType === 'CUSTOM'}
                                    error={hasError('frequencyMaxImpressions')}
                                    value={campaign.frequencyMaxImpressions ?? ''}
                                    onChange={handleChange}
                                    sx={{ width: '8%' }}
                                />{' '}
                                <Typography variant="caption" color="text.secondary">
                                    times per <FormLabelAsterisk />
                                </Typography>{' '}
                                <CDTextField
                                    variant="outlined"
                                    type="number"
                                    name="frequencyTimeUnitCount"
                                    required={campaign.frequencyType === 'CUSTOM'}
                                    error={hasError('frequencyTimeUnitCount')}
                                    value={campaign.frequencyTimeUnitCount ?? ''}
                                    onChange={handleChange}
                                    sx={{ width: '8%' }}
                                />
                                <CDTextField
                                    variant="outlined"
                                    select
                                    name="frequencyTimeUnit"
                                    required={campaign.frequencyType === 'CUSTOM'}
                                    error={hasError('frequencyTimeUnit')}
                                    value={campaign.frequencyTimeUnit ?? 'DAYS'}
                                    onChange={handleChange}
                                    sx={{
                                        width: '12%',
                                    }}
                                >
                                    <MenuItem value="DAYS">Days</MenuItem>
                                    <MenuItem value="HOURS">Hours</MenuItem>
                                </CDTextField>
                            </Row>

                            <Grid container spacing={3}>
                                <Grid item xs={1} />
                                <Grid item xs={2}>
                                    {hasError('frequencyMaxImpressions') && (
                                        <FormHelperText error={true} sx={{ ml: 7 }}>
                                            Value is required
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    {hasError('frequencyTimeUnitCount') && (
                                        <FormHelperText error={true}>Value is required</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        </FormControl>
                    )}
                </Column>
            </CardContent>
        </Card>
    );
}
