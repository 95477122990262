import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material';
import { Card, CardContent, CardHeader, FormLabel } from './CampaignWizardStyles';

import { CampaignWizardStepProps } from './CampaignWizard';
import { AudienceSource } from '../../../../types/AudienceSource';
import { toCampaignAdGroupAudienceSources } from '../../../../hooks/useAudienceSources';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import AudienceSourceField from '../../../../components/Audience/AudienceSourceField';
import CampaignAudienceTypeRadioGroup from '../CampaignAudienceTypeRadioGroup';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

type CampaignTargetingAudienceCardProps = {} & CampaignWizardStepProps;

export default function CampaignTargetingAudienceCard(props: CampaignTargetingAudienceCardProps) {
    const { campaign, onChange } = props;

    const $campaignWizard = useCampaignWizardContext();
    const { hasError } = $campaignWizard;

    const { $audienceSources, $loader } = $campaignWizard;
    const {
        audienceSources: selections = [],
        fetchAudienceSources: fetchSelections = () => {},
        isFetchingAudienceSources: isFetchingSelections = false,
        hydrateAudienceSources: hydrateSelections = (selections: AudienceSource[]) => [],
    } = $audienceSources || {};

    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name) {
            props.onChange({
                [event.target.name]: event.target.value,
            });
        }
    };

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchAudienceSources');
            } else {
                $loader.loaded('fetchAudienceSources');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if ($audienceSources) {
                const newAudienceSources: AudienceSource[] = $audienceSources.getCampaignAudienceSources(campaign);

                if (newAudienceSources.length) {
                    setAudienceSources(newAudienceSources);
                } else {
                    assignDefaultAudienceSources();
                }
            }
        } else {
            setAudienceSources([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups]);

    const updateAudienceSources = useCallback(
        (newAudienceSources: AudienceSource[]) => {
            setAudienceSources(newAudienceSources);

            if (onChange) {
                if (campaign?.adGroups?.length) {
                    campaign.adGroups[0] = {
                        ...campaign.adGroups[0],
                        adGroupAudienceSources: toCampaignAdGroupAudienceSources(
                            newAudienceSources,
                            campaign.adGroups[0].adGroupAudienceSources ?? []
                        ),
                    };
                } else {
                    campaign.adGroups.push({
                        adGroupAudienceSources: toCampaignAdGroupAudienceSources(newAudienceSources),
                    });
                }

                onChange({
                    adGroups: campaign.adGroups,
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onChange, campaign]
    );

    const assignDefaultAudienceSources = useCallback(() => {
        const newAudienceSources: AudienceSource[] = hydrateSelections(
            [
                '418042870179667348',
                '400690001636931136',
                '428078254701556779',
                '371466814155648275',
                '410334997859980072',
                '387582609423143493',
                '367454088916754363',
                '381776062289894133',
                '376664705684416936',
                '400230741649012650',
                '378075018889757615',
                '408243089917349549',
                '386306087666729446',
                '412812846697216883',
                '425043177702158897',
                '388767989456796718',
                '380817284209253346',
                '399902627195615527',
                '372402063791512503',
                '400379810603076489',
                '385465523562591870',
                '393406455482887264',
                '400829950299131072',
                '425799680259879622',
            ].map((dspAudienceId: string) => {
                return {
                    ...new AudienceSource(),
                    audienceId: dspAudienceId,
                };
            })
        );

        updateAudienceSources(newAudienceSources);
    }, [hydrateSelections, updateAudienceSources]);

    const handleAudienceSourceChange = (newAudienceSources: AudienceSource[]): void => {
        updateAudienceSources(newAudienceSources);
    };

    return (
        <Card id="campaign-wizard-targeting-audience" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Audience
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Row>
                    <FormControl fullWidth error={hasError('audienceType')}>
                        <Column gap={3}>
                            <FormLabel required={true}>Audience Categories and Segments</FormLabel>

                            <CampaignAudienceTypeRadioGroup
                                name="audienceType"
                                value={campaign.audienceType ?? ''}
                                onChange={handleChange}
                            />

                            {hasError('audienceType') && (
                                <FormHelperText error={true}>
                                    Campaign Audience Type selection is required
                                </FormHelperText>
                            )}

                            <Box>
                                <AudienceSourceField
                                    mediaType={campaign.mediaType}
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    hydrateSelections={hydrateSelections}
                                    onChange={handleAudienceSourceChange}
                                    items={audienceSources}
                                    slotProps={{
                                        button: {
                                            disabled: campaign.audienceType === 'AI',
                                        },
                                    }}
                                />
                            </Box>

                            {campaign.audienceType === 'CUSTOM' && (
                                <>
                                    {hasError('adGroups.*.adGroupAudienceSources') && (
                                        <FormHelperText error={true} sx={{ ml: 7 }}>
                                            Custom Audience(s) creation is required. Please add at least one audience.
                                        </FormHelperText>
                                    )}
                                </>
                            )}
                        </Column>
                    </FormControl>
                </Row>
            </CardContent>
        </Card>
    );
}
