import { Button, Card, CardContent, CardHeader, Paper, Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import { InfoMessageContext } from '../../components/GlobalInfoMessage';

class CardData {
    title: string | JSX.Element = '';
    mainContent: string = '';
    description: string = '';
}

export default function ContentCard(props: { cardData: CardData }) {
    const { cardData } = props;
    const { showMessage } = useContext(InfoMessageContext);
    return (
        <Paper sx={{ margin: '24px 0', whiteSpace: 'break-spaces' }}>
            <Card>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                    <CardHeader
                        title={cardData.title}
                        sx={{ borderBottom: '1px solid #0000001F' }}
                        titleTypographyProps={{ variant: 'body2' }}
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.secondary',
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                            }}
                            component="div"
                        >
                            <div>{cardData.description}</div>
                            <Tooltip title="Copy to clipboard">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        navigator.clipboard.writeText(cardData.mainContent);
                                        showMessage('success', 'Copied!');
                                    }}
                                >
                                    Copy
                                </Button>
                            </Tooltip>
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.primary',
                                padding: '16px',
                                border: '1px solid #e0e0e0',
                                backgroundColor: '#FAFAFA',
                                borderRadius: '4px',
                            }}
                        >
                            <div style={{ maxWidth: '600px' }}>{cardData.mainContent}</div>
                        </Typography>
                    </CardContent>
                </Typography>
            </Card>
        </Paper>
    );
}
