import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Grid,
    Paper,
    Toolbar,
    Typography,
    Tooltip,
} from '@mui/material';
import { GridToolbarContainer } from '../../components/DataGrid';
import { Close as CloseIcon, RefreshOutlined as RefreshOutlinedIcon } from '@mui/icons-material';
import Column from '../../components/Column';
import Row from '../../components/Row';
import SalesDataFirstPartyDataGrid, {
    getSalesDataFirstPartyDataGridColumns,
} from '../salesData/SalesDataFirstPartyDataGrid';
import SalesDataMAIDDataGrid from '../salesData/SalesDataMAIDDataGrid';
import { DataSetValue } from '../../types/DataSetValue';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { SalesDataMapping } from '../../types/SalesDataMapping';
import { MappedDataSummary } from '../../types/MappedDataSummary';
import { UserContext } from '../../App';
import CDButton from '../../components/CDButton';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

export default function CRMDataUploadViewDialog(props: {
    salesDataUpload: SalesDataUpload;
    onClose(): void;
    open: boolean;
    handleOpenSendToAmcDialog(): void;
}) {
    const { salesDataUpload, onClose, open, handleOpenSendToAmcDialog } = props;

    const { userContext } = useContext(UserContext);

    const [salesDataMapping, setSalesDataMapping] = useState<SalesDataMapping>({
        dealerId: 0,
        dateFormat: '',
        fields: [],
    });
    const [mappedDataSummary, setMappedDataSummary] = useState<MappedDataSummary>();
    const [sampleMappedData, setSampleMappedData] = useState<DataSetValue[]>([]);

    const getMappedDataSummary = useCallback(
        (mapping: SalesDataMapping) => {
            ApiService.getMappedDataSummary(salesDataUpload.id, mapping)
                .then((response) => {
                    setMappedDataSummary(response.data);

                    let dataSetValues = response.data.dataSetValues;
                    if (dataSetValues.length > 0) {
                        let id = 0;
                        dataSetValues.forEach((r) => (r.id = id++));
                        setSampleMappedData(dataSetValues);
                    }
                })
                .catch(() => {});
        },
        [salesDataUpload.id]
    );

    const loadMapping = useCallback(() => {
        ApiService.getSalesDataMapping(salesDataUpload.id)
            .then((response) => {
                setSalesDataMapping(response.data);
                getMappedDataSummary(response.data);
            })
            .catch(() => {});
    }, [getMappedDataSummary, salesDataUpload.id]);

    const salesDataUploadDateRange: string = useMemo(() => {
        const dateRanges: string[] = [];

        if (salesDataUpload?.beginDate) {
            dateRanges.push(Utils.formatDate(salesDataUpload.beginDate));
        }

        if (salesDataUpload?.endDate) {
            dateRanges.push(Utils.formatDate(salesDataUpload.endDate));
        }

        return dateRanges.join(' - ');
    }, [salesDataUpload]);

    const failedUploadStatus = useMemo((): boolean => {
        return ['Failed', 'Upload Failed'].includes(salesDataUpload.status as string);
    }, [salesDataUpload]);

    useEffect(() => {
        loadMapping();
    }, [loadMapping]);

    // Data Set data contains Mobile Advertising ID data
    function isMAIDDataSet(): boolean {
        return salesDataUpload.crmDataType.indexOf('MAID') > -1;
    }

    function formatValue(value: any): any {
        if (value === null || value === undefined) {
            return 'N/A';
        }

        return value;
    }

    function handleDownload(salesDataUploadId: number) {
        ApiService.downloadSalesData(salesDataUploadId)
            .then((response) => {
                Utils.downloadFile(response, 'text/csv');
            })
            .catch(() => {});
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Row justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="subtitle2">CRM Data Mapped to AMC</Typography>
                    </Box>

                    <Toolbar disableGutters>
                        <Row>
                            <CDButton variant="outlined" startIcon={<RefreshOutlinedIcon />} onClick={loadMapping}>
                                Refresh
                            </CDButton>
                        </Row>
                    </Toolbar>
                </Row>
            </GridToolbarContainer>
        );
    }

    const MAX_FILENAME_LENGTH = 30;

    function truncateFilename(filename: string): string {
        if (filename.length <= MAX_FILENAME_LENGTH) {
            return filename;
        }
        return filename.substring(0, MAX_FILENAME_LENGTH) + '...';
    }

    return (
        <Dialog
            maxWidth="lg"
            open={open}
            onClose={() => onClose()}
            sx={{
                zIndex: 1300,
            }}
        >
            <DialogTitle>Upload Details</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => onClose()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                <Column gap={3}>
                    <Column gap={1}>
                        {mappedDataSummary?.validations.lastUpload && (
                            <Alert severity="info">
                                <strong>{mappedDataSummary.validations.lastUpload.uploadedByName}</strong> last uploaded{' '}
                                <strong>{Utils.formatDate(salesDataUpload.beginDate)}</strong>
                                {' - '}
                                <strong>{Utils.formatDate(salesDataUpload.endDate)}</strong>{' '}
                                <strong>{mappedDataSummary.validations.lastUpload.crmDataType}</strong> data on{' '}
                                <strong>
                                    {Utils.formatDate(mappedDataSummary.validations.lastUpload.dateUploaded)}
                                </strong>
                            </Alert>
                        )}

                        {failedUploadStatus === true && salesDataUpload.errorMessage != null && (
                            <Alert
                                severity="error"
                                sx={{
                                    '& .MuiAlert-icon': {
                                        marginTop: 0.5,
                                    },
                                }}
                            >
                                <Row alignItems="center">
                                    <Typography>{salesDataUpload.errorMessage}</Typography>
                                </Row>
                            </Alert>
                        )}

                        {failedUploadStatus === false && !salesDataUpload.workflowExecutionStatus && (
                            <>
                                {salesDataUpload.amcInstanceId ? (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            '& .MuiAlert-icon': {
                                                marginTop: 0.5,
                                            },
                                        }}
                                    >
                                        <Row alignItems="center">
                                            <Typography>Data has not been submitted to AMC.</Typography>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    onClose();
                                                    handleOpenSendToAmcDialog();
                                                }}
                                            >
                                                Send to AMC
                                            </Button>
                                        </Row>
                                    </Alert>
                                ) : (
                                    <Alert
                                        severity="warning"
                                        sx={{
                                            '& .MuiAlert-icon': {
                                                marginTop: 0.5,
                                            },
                                        }}
                                    >
                                        <Row alignItems="center">
                                            <Typography>
                                                AMC Instance not setup for this Advertiser. Please contact{' '}
                                                <a href="mailto:support@cognitionads.com">support@cognitionads.com</a>{' '}
                                                for assistance.
                                            </Typography>
                                        </Row>
                                    </Alert>
                                )}
                            </>
                        )}

                        <Paper variant="outlined" sx={{ p: 2 }}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Advertiser</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {salesDataUpload.dealerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Data Type</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUpload.crmDataType)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">User</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {salesDataUpload.uploadedByName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Upload Date</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {Utils.formatDate(salesDataUpload.dateUploaded)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">File Name</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {userContext.isAdmin() && (
                                            <Button
                                                sx={{ textDecoration: 'underline', p: 0 }}
                                                color="info"
                                                onClick={() => {
                                                    handleDownload(salesDataUpload.id);
                                                }}
                                            >
                                                <Tooltip title={formatValue(salesDataUpload.originalFileName)}>
                                                    {formatValue(truncateFilename(salesDataUpload.originalFileName))}
                                                </Tooltip>
                                            </Button>
                                        )}
                                        {!userContext.isAdmin() && (
                                            <>
                                                <Tooltip title={formatValue(salesDataUpload.originalFileName)}>
                                                    {formatValue(truncateFilename(salesDataUpload.originalFileName))}
                                                </Tooltip>
                                            </>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Date Range</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUploadDateRange)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Records Submitted</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUpload.recordsSubmitted)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Records Identified</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUpload.recordsIdentified)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Workflow Status</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUpload.workflowExecutionStatus)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="caption">Workflow Type</Typography>
                                    <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
                                        {formatValue(salesDataUpload.amcWorkflowId)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Column>

                    {userContext.isAdmin() && (
                        <Column gap={1}>
                            <Box>
                                {!isMAIDDataSet() && (
                                    <SalesDataFirstPartyDataGrid
                                        columns={getSalesDataFirstPartyDataGridColumns(salesDataMapping)}
                                        rows={sampleMappedData}
                                        components={{ Toolbar: CustomToolbar }}
                                    />
                                )}
                                {isMAIDDataSet() && <SalesDataMAIDDataGrid rows={sampleMappedData} />}
                            </Box>
                        </Column>
                    )}
                </Column>
            </DialogContent>
        </Dialog>
    );
}
