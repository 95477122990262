import { GridColumns, GridFooterContainer, GridRowModel, GridSortModel } from '@mui/x-data-grid';
import CDGrid, { CustomPagination } from '../../components/CDGrid';
import { AdCreativeSource, AdTagPlacement, AdTagPlacementStatus, AdTagType, DspPlatform } from '../../types/AdTag';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';
import { AlertColor, Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Delete, Edit, FileCopy, Refresh, Wifi, WifiOff } from '@mui/icons-material';
import EditAdTagPlacement, { EditAdTagPlacementRef } from './EditAdTagPlacement';
import { AxiosPageableResponse } from '../../types/AxiosPageableResponse';

import useWebSocket from '../../hooks/useWebSocket';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import Heading from '../../components/Heading';
import CDButton from '../../components/CDButton';
import AccessGuard from '../../components/AccessGuard';
import { USER_GROUP_ADMIN } from '../../types/User';
import AgencySelector from '../../components/AgencySelector';
import { AdvertiserContext } from '../../AdvertiserContext';
import { Dealer } from '../../types/Dealer';
import Row from '../../components/Row';
import { UserContext } from '../../App';
import CDAutocompleteEndless from '../../components/CDAutocompleteEndless';

function AdTagGridFooter(props: {
    editAdTagPlacementRef: React.RefObject<EditAdTagPlacementRef>;
    reloadGrid: () => void;
    showInfoMessage: (alertColor: AlertColor, message: string, onClose?: () => void) => void;
    wsState: number;
    toggleWebSocket: () => void;
}) {
    const isOpen = props.wsState === WebSocket.OPEN;
    const isConnecting = props.wsState === WebSocket.CONNECTING;
    const title = isOpen ? 'Click to disconnect from real-time updates' : 'Click to connect to real-time updates';
    const sx = {
        ...{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' },
        ...{ width: 30, height: 30, borderRadius: '50%', backgroundColor: isOpen ? 'green' : 'lightgray' },
    };
    const wifi = isOpen ? <Wifi sx={{ color: 'white' }} /> : <WifiOff sx={{ color: 'white' }} />;
    return (
        <GridFooterContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginLeft: '10px' }}>
                {isConnecting ? (
                    <Box sx={sx} title="Connecting...">
                        <CircularProgress size={20} />
                    </Box>
                ) : (
                    <Box sx={sx} title={title} onClick={props.toggleWebSocket}>
                        {wifi}
                    </Box>
                )}
                <Box>
                    <IconButton onClick={props.reloadGrid} aria-label="refresh" title="Refresh Grid">
                        <Refresh />
                    </IconButton>
                </Box>
                <EditAdTagPlacement
                    ref={props.editAdTagPlacementRef}
                    reloadGrid={props.reloadGrid}
                    showInfoMessage={props.showInfoMessage}
                />
            </Box>

            <CustomPagination />
        </GridFooterContainer>
    );
}

export default function AdTagPlacementPage() {
    const editAdTagPlacementRef = useRef<EditAdTagPlacementRef>(null);
    const [adTagPlacements, setAdTagPlacements] = useState<AdTagPlacement[]>([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [rowCount, setRowCount] = useState(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'updatedDate', sort: 'desc' }]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [triggerReload, setTriggerReload] = useState(false);
    const [tooltipOpenRow, setTooltipOpenRow] = useState<number | null>(null);
    const [hoverTooltipOpenRow, setHoverTooltipOpenRow] = useState<number | null>(null);
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);
    const { userContext } = useContext(UserContext);

    const topHeight = userContext.isAdmin() ? '245px' : '205px';

    const handleDealerSelect = useCallback(
        (dealer: Dealer | null) => {
            setPage(0);
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    function showInfoMessage(alertColor: AlertColor, message: string, onClose?: () => void) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
                if (onClose) onClose();
            },
        });
    }

    const reloadGridToPage0 = (bypassWaitIndicator = true) => {
        if (page !== 0) setPage(0);
        else reloadGrid(bypassWaitIndicator);
    };

    const reloadGrid = async (bypassWaitIndicator?: boolean) => {
        const response: AxiosPageableResponse<AdTagPlacement> = await ApiService.getAdTagPlacements(
            advertiserContext.agencyId,
            advertiserContext.dealer?.id,
            page,
            pageSize,
            sortModel,
            bypassWaitIndicator
        );
        if (response.status === 200) {
            setAdTagPlacements(response.data.content);
            setRowCount(response.data.totalElements);
        }
    };

    useEffect(() => {
        reloadGrid();
    }, [advertiserContext.agencyId, advertiserContext.dealer, page, pageSize, sortModel]);

    const { stompClient, readyState: wsState } = useWebSocket({
        topic: '/topic/adTagPlacement/publish',
        triggerReload,
        onMessage: (message) => {
            reloadGridToPage0();
            if (!message) return;
            const adTagPlacement: AdTagPlacement = JSON.parse(message.body);
            console.log('Received adTagPlacement:', adTagPlacement);
            if (adTagPlacement.status === AdTagPlacementStatus.ERROR) {
                showInfoMessage('error', `Error processing ${adTagPlacement.adName}: ${adTagPlacement.processMessage}`);
            }
            // const index = adTagPlacements.findIndex((placement) => placement.id === adTagPlacement.id);
            // if (index === -1) {
            //     reloadGridToPage0();
            // } else {
            //     setAdTagPlacements((prev) => {
            //         const updated = [...prev];
            //         updated[index] = adTagPlacement;
            //         return updated;
            //     });
            // }
        },
    });

    const toggleWebSocket = () => {
        if (wsState === WebSocket.OPEN) stompClient?.deactivate();
        else setTriggerReload(!triggerReload);
    };

    const handleCopyToClipboard = (adUrl: string, rowIndex: number) => {
        if (adUrl) {
            navigator.clipboard.writeText(adUrl).then(() => {
                setTooltipOpenRow(rowIndex);
                setHoverTooltipOpenRow(null);
                setTimeout(() => {
                    setTooltipOpenRow(null);
                    setHoverTooltipOpenRow(null);
                }, 2000); // Show tooltip for 2 seconds
            });
        }
    };

    const handleEdit = (placement?: AdTagPlacement) => {
        if (editAdTagPlacementRef.current) {
            editAdTagPlacementRef.current.edit(advertiserContext.agencyId, placement);
        }
    };

    const handleDelete = async (placement: AdTagPlacement) => {
        if (!window.confirm('Are you sure you want to disconnect this ad tag placement?')) {
            return;
        }
        await ApiService.deleteAdTagPlacement(placement.id as number);
        reloadGrid();
    };

    const columns: GridColumns = [
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (rowData) => {
                if (AdTagPlacementStatus.ERROR === rowData.value) {
                    return (
                        <Tooltip title={rowData.row.processMessage}>
                            <span style={{ color: 'red' }}>{rowData.value}</span>
                        </Tooltip>
                    );
                }
                return rowData.value;
            },
        },
        {
            field: 'dealer',
            headerName: 'Advertiser',
            valueGetter: (params) => params.value?.dealerName,
            flex: 2,
        },
        {
            field: 'campaign',
            headerName: 'Campaign',
            valueGetter: (params) => params.value?.campaignName,
            hide: true,
            flex: 1,
        },
        {
            field: 'campaignAdGroup',
            headerName: 'Ad Group',
            valueGetter: (params) => params.value?.name,
            hide: true,
            flex: 1,
        },
        {
            field: 'dspPlatform',
            headerName: 'DSP Platform',
            flex: 1,
            valueGetter: (params) => DspPlatform[params.value as keyof typeof DspPlatform],
        },
        {
            field: 'tagType',
            headerName: 'Tag Type',
            valueGetter: (params) => AdTagType[params.value as keyof typeof AdTagType],
            flex: 1,
        },
        {
            field: 'adCreativeSource',
            headerName: 'Creative Source',
            valueGetter: (params) => AdCreativeSource[params.value as keyof typeof AdCreativeSource],
            hide: true,
            flex: 1,
        },
        {
            field: 'adTagImportSource',
            headerName: 'Source Folder',
            valueGetter: (params) => (params.value ? `${params.value.s3Bucket}/${params.value.s3IncomingPath}` : ''),
            hide: true,
            flex: 1.5,
        },

        { field: 'adName', headerName: 'Ad Tag Name', flex: 3 },
        { field: 'projectId', headerName: 'Last Project ID', hide: true, flex: 2 },
        { field: 'adUrl', headerName: 'Ad Tag URL', hide: true, flex: 3 },
        { field: 'filename', headerName: 'Latest Creative File', flex: 3 },
        {
            field: 'updatedDate',
            headerName: 'Updated Date',
            valueGetter: (params) => Utils.formatDateTime(params.value),
            flex: 1.5,
        },
        {
            headerName: 'Actions',
            field: 'actions',
            sortable: false,
            renderCell: (rowData: any) => (
                <>
                    <Tooltip
                        title="Ad Tag Copied!"
                        open={tooltipOpenRow === rowData.id}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={() => setTooltipOpenRow(null)}
                        placement="left"
                    >
                        <Tooltip
                            title={
                                rowData.row.adUrl ? 'Copy Ad Tag URL' : 'Ad Tag URL not available. Please re-generate.'
                            }
                            open={hoverTooltipOpenRow === rowData.id && tooltipOpenRow !== rowData.id}
                            onOpen={() => setHoverTooltipOpenRow(rowData.id)}
                            onClose={() => setHoverTooltipOpenRow(null)}
                            placement="left"
                        >
                            <IconButton
                                onClick={() => handleCopyToClipboard(rowData.row.adUrl, rowData.id)}
                                sx={{ opacity: rowData.row.adUrl ? 1 : 0.5 }}
                            >
                                <FileCopy sx={{ width: 20, height: 20 }} />
                            </IconButton>
                        </Tooltip>
                    </Tooltip>
                    <IconButton onClick={() => handleEdit(rowData.row)} aria-label="edit" title="Edit">
                        <Edit sx={{ width: 20, height: 20 }} />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(rowData.row)} aria-label="delete" title="Disconnect">
                        <Delete />
                    </IconButton>
                </>
            ),
            flex: 1.3,
        },
    ];

    return (
        <>
            <InfoMessage {...infoMessage} />
            <Heading>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    Ad Serving
                    <CDButton sx={{ marginLeft: '10px' }} onClick={() => handleEdit()} variant="contained">
                        Setup Ad Tag
                    </CDButton>
                </Box>
            </Heading>
            <Row style={{ gap: '15px', marginBottom: '20px' }}>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <div style={{ minWidth: '280px' }}>
                        <AgencySelector
                            variant="outlined"
                            label="Agency"
                            agencySelected={(agencyId) => {
                                setPage(0);
                                setAdvertiserContext(null, agencyId);
                            }}
                            agencyId={advertiserContext.agencyId}
                        />
                    </div>
                </AccessGuard>

                <div style={{ minWidth: '500px' }}>
                    <CDAutocompleteEndless
                        label="Advertiser"
                        parentId={advertiserContext.agencyId}
                        value={advertiserContext.dealer}
                        onChange={handleDealerSelect}
                        handleFetch={async (page, name, parentId) => ApiService.getAllDealers(page, name, parentId)}
                        getOptionLabel={(option: Dealer) => option.dealerName}
                    />
                </div>
            </Row>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - ${topHeight})`, width: '100%' }}>
                <CDGrid
                    columns={columns}
                    rows={adTagPlacements}
                    processRowUpdate={(row: GridRowModel) => {
                        // ApiService.updateAdServerPlacement(row as CampaignAdServerPlacement);
                        return row;
                    }}
                    onProcessRowUpdateError={() => {}}
                    experimentalFeatures={{ newEditingApi: true }}
                    rowCount={rowCount}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={setPage}
                    onSortModelChange={setSortModel}
                    onPageSizeChange={setPageSize}
                    autoHeight={false}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sortModel}
                    components={{ Footer: AdTagGridFooter }}
                    componentsProps={{
                        footer: {
                            editAdTagPlacementRef,
                            reloadGrid: reloadGridToPage0,
                            showInfoMessage,
                            wsState,
                            toggleWebSocket,
                        },
                    }}
                    sx={{ flexGrow: 1 }}
                />
            </Box>
        </>
    );
}
