import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async (dealerId: number) => {
    const { data } = await ApiService.getCampaignCPMValues(dealerId);
    return data;
};

const useCampaignCPMValueListQuery = (dealerId: number) => {
    return useQuery(['campaignCPMValueList', dealerId], () => fetchData(dealerId), {
        staleTime: Infinity,
        enabled: dealerId > 0,
    });
};

export { useCampaignCPMValueListQuery };
