import { useContext, useEffect } from 'react';
import { Box, List } from '@mui/material';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import { UserContext } from '../../../../App';
import { ListItemButton, ListItemLabel } from './CampaignWizardStyles';
import { ScrollableSection } from '../../../../hooks/ScrollSpy';
import { useScrollSpy } from '../../../../hooks/ScrollSpy';

type CampaignWizardSetupStepNavProps = {
    // step: number;
    onStep?: (step: number) => void;
};

export const sections: ScrollableSection[] = [
    { index: 1, id: 'campaign-wizard-setup-advertiser' },
    { index: 2, id: 'campaign-wizard-setup-plan' },
    { index: 3, id: 'campaign-wizard-setup-inventory' },
    { index: 4, id: 'campaign-wizard-setup-delivery' },
    { index: 5, id: 'campaign-wizard-setup-optimization' },
    { index: 6, id: 'campaign-wizard-setup-frequency' },
];

export default function CampaignWizardSetupStepNav(props: CampaignWizardSetupStepNavProps) {
    const { onStep } = props;
    const { userContext } = useContext(UserContext);
    const { hasError } = useCampaignWizardContext();
    const { activeSection, jumpToActiveSection } = useScrollSpy({ sections });

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        jumpToActiveSection(index);
    };

    useEffect(() => {
        if (onStep) {
            onStep(activeSection);
        }
    }, [onStep, activeSection]);

    const hasErrorSection = (section: string): boolean => {
        switch (section) {
            case 'Advertiser Info':
                return hasError('dealerId') || hasError('adGroups.*.adGroupProductCategories');

            case 'Media Type & Strategy':
                return hasError('mediaType') || hasError('strategyType');

            case 'Goal & Optimization':
                return hasError('goal');

            case 'Inventory':
                return hasError('adGroups.*.adGroupInventorySources');

            case 'Schedule & Budget':
                return (
                    hasError('startDate') ||
                    hasError('endDate') ||
                    hasError('budget') ||
                    hasError('impressionGoal') ||
                    hasError('budgetCapType') ||
                    hasError('budgetCapRecurrenceTimePeriod') ||
                    hasError('budgetCapAmount')
                );

            case 'Exposure Frequency':
                return (
                    hasError('frequencyType') ||
                    hasError('frequencyMaxImpressions') ||
                    hasError('frequencyTimeUnitCount') ||
                    hasError('frequencyTimeUnit') ||
                    hasError('frequencyMaxImpressions') ||
                    hasError('frequencyTimeUnitCount')
                );
        }

        return false;
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List component="nav" aria-label="secondary campaign setup sections">
                <ListItemButton selected={activeSection === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemLabel error={hasErrorSection('Advertiser Info')} primary="Advertiser Info" />
                </ListItemButton>
                <ListItemButton selected={activeSection === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemLabel error={hasErrorSection('Media Type & Strategy')} primary="Media Type & Strategy" />
                </ListItemButton>
                <ListItemButton selected={activeSection === 5} onClick={(event) => handleListItemClick(event, 5)}>
                    <ListItemLabel error={hasErrorSection('Goal & Optimization')} primary="Goal & Optimization" />
                </ListItemButton>
                {userContext.isAdmin() && (
                    <ListItemButton selected={activeSection === 3} onClick={(event) => handleListItemClick(event, 3)}>
                        <ListItemLabel error={hasErrorSection('Inventory')} primary="Inventory" />
                    </ListItemButton>
                )}
                <ListItemButton selected={activeSection === 4} onClick={(event) => handleListItemClick(event, 4)}>
                    <ListItemLabel error={hasErrorSection('Schedule & Budget')} primary="Schedule & Budget" />
                </ListItemButton>
                <ListItemButton selected={activeSection === 6} onClick={(event) => handleListItemClick(event, 6)}>
                    <ListItemLabel error={hasErrorSection('Exposure Frequency')} primary="Exposure Frequency" />
                </ListItemButton>
            </List>
        </Box>
    );
}
