import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async () => {
    const { data } = await ApiService.getDspProductCategories();
    return data;
};

const useDspProductCategoryListQuery = () => {
    return useQuery(['dspProductCategoryList'], () => fetchData(), {
        staleTime: Infinity,
        enabled: false,
    });
};

export { useDspProductCategoryListQuery };
