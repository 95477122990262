import moment from 'moment-timezone';

import { AttributionStatusSet, PolkSalesWorkflow, PolkSalesWorkflowSet } from '../types/Polk';
import Utils from '../components/Utils';

export function isOpportunitySalesWorkflow(salesWorkflow: PolkSalesWorkflow): boolean {
    return salesWorkflow?.amcUpload?.dataSet === 'polk_opportunities';
}

export function isSalesSalesWorkflow(salesWorkflow: PolkSalesWorkflow): boolean {
    return salesWorkflow?.amcUpload?.dataSet === 'polk_sales';
}

export function populatePolkSalesWorkflowSets(polkSalesWorkflows: PolkSalesWorkflow[]) {
    const polkSalesWorkflowSets: PolkSalesWorkflowSet[] = [];
    const polkSalesWorkflowGroups: Record<string, Record<string, number[]>> = {};

    polkSalesWorkflows.forEach((polkSalesWorkflow: PolkSalesWorkflow, index: number) => {
        const beginDate: string = moment(Utils.getDate(polkSalesWorkflow.amcUpload.beginDate)).format('YYYY-MM-DD');
        const dataSet: string = polkSalesWorkflow?.amcUpload?.dataSet as string;

        if (!polkSalesWorkflowGroups[beginDate]) {
            polkSalesWorkflowGroups[beginDate] = {
                polk_sales: [],
                polk_opportunities: [],
            };
        }

        if (!polkSalesWorkflowGroups[beginDate][dataSet]) {
            polkSalesWorkflowGroups[beginDate][dataSet] = [];
        }

        polkSalesWorkflowGroups[beginDate][dataSet].push(index);
    });

    Object.entries(polkSalesWorkflowGroups).forEach(([beginDate, dataSets]) => {
        const polkSalesDataSets = dataSets['polk_sales'] || [];
        const polkOpportunitiesDataSets = dataSets['polk_opportunities'] || [];
        const dataSetMaxLength: number = Math.max(polkSalesDataSets.length, polkOpportunitiesDataSets.length);

        for (let index: number = 0; index < dataSetMaxLength; index++) {
            let polkSalesWorkflowSet: PolkSalesWorkflowSet = {
                beginDate: new Date(),
                endDate: new Date(),
                salesWorkflow: polkSalesWorkflows[polkSalesDataSets[index] ?? null] || null,
                opportunityWorkflow: polkSalesWorkflows[polkOpportunitiesDataSets[index] ?? null] || null,
            };

            if (polkSalesWorkflowSet.salesWorkflow) {
                polkSalesWorkflowSet.beginDate = Utils.getDate(
                    polkSalesWorkflowSet.salesWorkflow.amcUpload.beginDate
                ) as Date;
                polkSalesWorkflowSet.endDate = Utils.getDate(
                    polkSalesWorkflowSet.salesWorkflow.amcUpload.endDate
                ) as Date;
            }

            if (polkSalesWorkflowSet.opportunityWorkflow) {
                polkSalesWorkflowSet.beginDate = Utils.getDate(
                    polkSalesWorkflowSet.opportunityWorkflow.amcUpload.beginDate
                ) as Date;
                polkSalesWorkflowSet.endDate = Utils.getDate(
                    polkSalesWorkflowSet.opportunityWorkflow.amcUpload.endDate
                ) as Date;
            }

            polkSalesWorkflowSets.push(polkSalesWorkflowSet);
        }
    });

    return polkSalesWorkflowSets;
}

export function popoluteAttributionStatusSets(polkSalesWorkflowSets: PolkSalesWorkflowSet[]): AttributionStatusSet[] {
    const attributionStatusSets: AttributionStatusSet[] = [];

    const findAttributionStatusSetIndex = (polkSalesWorkflowSet: PolkSalesWorkflowSet): number => {
        return attributionStatusSets.findIndex((attributionStatusSet) =>
            moment(attributionStatusSet.beginDate).isSame(polkSalesWorkflowSet.beginDate, 'day')
        );
    };

    if (polkSalesWorkflowSets.length > 0) {
        polkSalesWorkflowSets.forEach((polkSalesWorkflowSet) => {
            let attributionStatusSetIndex: number = findAttributionStatusSetIndex(polkSalesWorkflowSet);

            if (attributionStatusSetIndex === -1) {
                let attributionStatusSet = {
                    beginDate: polkSalesWorkflowSet.beginDate,
                    endDate: polkSalesWorkflowSet.endDate,
                    currentSalesWorkflowSet: polkSalesWorkflowSet,
                    previousSalesWorkflowSets: [polkSalesWorkflowSet],
                };
                attributionStatusSets.push(attributionStatusSet);
                attributionStatusSetIndex = findAttributionStatusSetIndex(polkSalesWorkflowSet);
            } else {
                const set = attributionStatusSets[attributionStatusSetIndex];
                const cur = set.currentSalesWorkflowSet?.salesWorkflow;
                if (cur?.status !== 'SUCCEEDED' && polkSalesWorkflowSet?.salesWorkflow?.status === 'SUCCEEDED')
                    set.currentSalesWorkflowSet = polkSalesWorkflowSet;
                set.previousSalesWorkflowSets.push(polkSalesWorkflowSet);
            }
        });
    }

    return attributionStatusSets;
}
