import { Button } from '@mui/material';

import { Campaign } from '../../../types/Campaign';
import CampaignStatusChip from './CampaignStatusChip';
import CDGrid from '../../../components/CDGrid';
import Utils from '../../../components/Utils';

type CampaignDuplicateDataGridProps = {
    rows: Campaign[];
    onView?: (campaign: Campaign) => void;
    loading?: boolean;
};

export default function CampaignDuplicateDataGrid(props: CampaignDuplicateDataGridProps) {
    const { loading = false } = props;

    const handleView = (campaign: Campaign) => {
        if (props.onView) {
            props.onView(campaign);
        }
    };

    return (
        <CDGrid
            loading={loading}
            variant="contained"
            density="compact"
            autoHeight={true}
            headerHeight={75}
            rowHeight={75}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            columns={[
                {
                    width: 200,
                    field: 'campaignName',
                    headerName: 'Campaign',
                },
                {
                    width: 120,
                    field: 'status',
                    headerName: 'Status',
                    renderCell: (params) => {
                        return <CampaignStatusChip deliveryStatus={params.row.deliveryStatus} />;
                    },
                },
                {
                    width: 120,
                    field: 'startDate',
                    headerName: 'Start Date',
                    valueGetter: (params) => {
                        return Utils.formatDate(params.row.startDate);
                    },
                    renderCell: (params) => {
                        return Utils.formatESTDateLong(params.row.startDate);
                    },
                },
                {
                    width: 120,
                    field: 'endDate',
                    headerName: 'End Date',
                    valueGetter: (params) => {
                        return Utils.formatDate(params.row.endDate);
                    },
                    renderCell: (params) => {
                        return Utils.formatESTDateLong(params.row.endDate);
                    },
                },
                {
                    width: 120,
                    field: 'budget',
                    headerName: 'Budget',
                    renderCell: (params) => {
                        return Utils.formatCurrency(params.row.budget);
                    },
                },
                {
                    width: 120,
                    field: 'Action',
                    headerName: 'Action',
                    headerAlign: 'center',
                    align: 'center',
                    renderCell: (params) => {
                        return (
                            <Button variant="text" size="medium" onClick={(event) => handleView(params.row)}>
                                Review
                            </Button>
                        );
                    },
                },
            ]}
            rows={props.rows}
        />
    );
}
