import { useEffect, useRef, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client, Frame } from '@stomp/stompjs';

interface UseWebSocketProps {
    topic: string;
    triggerReload?: boolean;
    onMessage: (message: any) => void;
}

const useWebSocket = ({ topic, triggerReload, onMessage }: UseWebSocketProps) => {
    const stompClientRef = useRef<Client | null>(null);
    const [readyState, setReadyState] = useState<number>(WebSocket.CLOSED);

    useEffect(() => {
        setReadyState(WebSocket.CONNECTING);
        const socket = new SockJS(`/api/ws`, null, {
            withCredentials: true,
        });
        const stompClient = new Client({
            webSocketFactory: () => socket,
            connectHeaders: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            debug: (str) => console.log(str),
            onConnect: () => {
                setReadyState(WebSocket.OPEN);
                onMessage(null);
                stompClient.subscribe(topic, (message) => onMessage(message));
            },
            onStompError: (frame: Frame) => {
                console.error('Broker reported error: ' + frame.headers['message']);
                console.error('Additional details: ' + frame.body);
            },
            onWebSocketClose: () => {
                setReadyState(WebSocket.CLOSED);
            },
            onWebSocketError: (event) => {
                console.error('WebSocket error:', event);
                setReadyState(WebSocket.CLOSED);
            },
        });

        stompClient.activate();
        stompClientRef.current = stompClient;

        return () => {
            if (stompClientRef.current) stompClientRef.current.deactivate();
        };
    }, [topic, triggerReload]);

    return { stompClient: stompClientRef.current, readyState };
};

export default useWebSocket;
