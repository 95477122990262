import { Chip, FormControlLabel, Grid, Stack, Switch, Tooltip } from '@mui/material';
import { Dealer } from '../../types/Dealer';
import ContentCard from './ContentCard';
import AccessGuard from '../../components/AccessGuard';

import { USER_GROUP_ADMIN } from '../../types/User';
import { ChangeEvent } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    section: {
        padding: '15px',
        borderRadius: '6px',
        border: '1px solid #e0e0e0',
        position: 'relative',
    },
});

export default function HAT(props: { dealer: Dealer; handleChange: (event: ChangeEvent<HTMLInputElement>) => void }) {
    const { dealer, handleChange } = props;
    const classes = useStyles();
    let status = 'Not Tracking';
    let color = 'default';
    if (!dealer.agency?.tfCustomerId) {
        status = 'Not Tracking';
    } else if (dealer.agency.tfCustomerId && !dealer.tfApiToken) {
        status = 'Pending Tracking';
        color = 'warning';
    } else if (dealer.agency.tfCustomerId && dealer.tfTokenIsActive) {
        status = 'Tracking';
        color = 'success';
    } else if (dealer.agency.tfCustomerId && !dealer.tfTokenIsActive) {
        status = 'Stopped Tracking';
        color = 'error';
    }

    const snippetData = {
        title: getTitleElement('Tracking Code', status, color),
        mainContent: `<!-- HAT Start --> \n ${dealer.tfSnippet}\n<!-- HAT End -->`,
        description:
            "Below is the Website Tag for this advertiser. Copy and paste it in the code of every page of your website, immediately after the 'head' element. This can be done through a Tag Manager like GTM. Don’t add more than one tag to each page.",
    };
    const apiKeyData = {
        title: 'API Token',
        mainContent: dealer.tfApiToken,
        description: 'Below is the API Token for this advertiser. Use it when sending events programmatically.',
    };

    function getTitleElement(title: string, status: string, colorCode: any) {
        return (
            <>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <span>{title}</span>
                    <span style={{ flex: 1 }} />
                    <Chip label={status} size="small" color={colorCode} />
                </Stack>
            </>
        );
    }

    return (
        <>
            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                <Grid item className={classes.section}>
                    <FormControlLabel
                        control={
                            <>
                                <Tooltip title="HAT Integrations">
                                    <Switch
                                        checked={dealer.tfTokenIsActive}
                                        onChange={handleChange}
                                        name={'tfTokenIsActive'}
                                        disabled={dealer.agency?.tfUserIsActive ? false : true}
                                    />
                                </Tooltip>
                            </>
                        }
                        label="HAT Integrations"
                    />
                </Grid>
            </AccessGuard>

            <ContentCard cardData={snippetData} />
            <ContentCard cardData={apiKeyData} />
        </>
    );
}
