import { useCallback, useEffect, useRef, useState } from 'react';

import { Campaign, CampaignAdGroupProductCategory } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { DspProductCategory } from '../types/DspProductCategory';
import { ProductCategory } from '../components/ProductCategoryAutocomplete';
import { useDspProductCategoryListQuery } from '../hooks/Queries/DspProductCategoryListQuery';

export const isChildProductCategory = (productCategory: ProductCategory): boolean => {
    return productCategory.parentId != null && productCategory.parentId.length > 0;
};

export function toCampaignAdGroupProductCategories(
    productCategories: ProductCategory[],
    adGroupProductCategories: CampaignAdGroupProductCategory[] = []
): CampaignAdGroupProductCategory[] {
    return [
        ...adGroupProductCategories.filter((x: CampaignAdGroupProductCategory) => {
            return productCategories.find((y: ProductCategory) => x.dspCategoryId === y.id) !== undefined;
        }),
        ...productCategories
            .filter((x: ProductCategory) => {
                if (isChildProductCategory(x)) {
                    return (
                        adGroupProductCategories.find(
                            (y: CampaignAdGroupProductCategory) => x.id === y.dspCategoryId
                        ) === undefined
                    );
                }

                return false;
            })
            .map((productCategory: ProductCategory) => {
                return {
                    dspCategoryId: productCategory.id,
                };
            }),
    ];
}

export function toProductCategories(adGroupProductCategories: CampaignAdGroupProductCategory[]): ProductCategory[] {
    let productCategories: ProductCategory[] = [];

    if (adGroupProductCategories.length > 0) {
        productCategories.push(
            ...adGroupProductCategories.map((adGroupProductCategory: CampaignAdGroupProductCategory) => {
                return {
                    id: adGroupProductCategory.dspCategoryId,
                    parentId: '',
                    name: '',
                };
            })
        );
    }

    return productCategories;
}

// ProductCategory is an alias of DspProductCategoryDTO
export function toDspProductCategoryDTO(dspProductCategory: DspProductCategory): ProductCategory {
    const dspProductCategoryDTO: ProductCategory = {
        id: dspProductCategory.categoryId,
        parentId: dspProductCategory.parentCategoryId,
        name: dspProductCategory.name,
    };

    return dspProductCategoryDTO;
}

export interface ProductCategories {
    //
    productCategories: ProductCategory[];
    setProductCategories(productCategories: ProductCategory[]): void;
    fetchProductCategories(): void;
    isFetchingProductCategories: boolean;

    //
    getProductCategoryName(category: ProductCategory): string;
    getCampaignProductCategory(campaign: Campaign): ProductCategory | null;
    getCampaignProductCategories(campaign: Campaign): ProductCategory[];

    //
    hydrateProductCategory(unhydratedProductCategory: ProductCategory): ProductCategory;
    hydrateProductCategories(unhydratedProductCategories: ProductCategory[]): ProductCategory[];
}

type ProductCategoryProps = {
    dealer: Dealer | null | undefined;
};

export default function useProductCategories(props: ProductCategoryProps): ProductCategories {
    const { dealer } = props;
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
    const isFetched = useRef<boolean>(false);

    const { data, refetch, isFetching } = useDspProductCategoryListQuery();

    const fetchProductCategories = useCallback(() => {
        if (!data) {
            refetch();
        }
    }, [dealer, data, refetch]);

    useEffect(() => {
        setProductCategories(data ? data.map((model: DspProductCategory) => toDspProductCategoryDTO(model)) : []);
    }, [data]);

    const getProductCategoryName = (productCategory: ProductCategory): string => {
        return hydrateProductCategory(productCategory)?.name;
    };

    const getCampaignProductCategory = (campaign: Campaign): ProductCategory | null => {
        let hydratedProductCategory: ProductCategory | null | undefined = null;

        const hydratedProductCategories: ProductCategory[] = getCampaignProductCategories(campaign);

        if (hydratedProductCategories.length > 0) {
            hydratedProductCategory = hydratedProductCategories.find((o: ProductCategory) => !o.parentId);
        }

        if (!hydratedProductCategory) {
            const childProductCategory: ProductCategory | null | undefined = hydratedProductCategories.find(
                (o: ProductCategory) => o.parentId != null
            );

            if (childProductCategory) {
                hydratedProductCategory = productCategories.find(
                    (o: ProductCategory) => o.id === childProductCategory.parentId
                );
            }
        }

        return hydratedProductCategory === undefined ? null : hydratedProductCategory;
    };

    const getCampaignProductCategories = (campaign: Campaign): ProductCategory[] => {
        let hydratedProductCategories: ProductCategory[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupProductCategories) {
                hydratedProductCategories = hydrateProductCategories(
                    toProductCategories(campaign.adGroups[0].adGroupProductCategories)
                );
            }
        }

        return hydratedProductCategories;
    };

    const hydrateProductCategory = (unhydratedProductCategory: ProductCategory): ProductCategory => {
        if (productCategories.length > 0) {
            const hydratedProductCategory = productCategories.find(
                (o: ProductCategory) => o.id === unhydratedProductCategory.id
            );

            if (hydratedProductCategory) {
                return hydratedProductCategory;
            }
        }

        return unhydratedProductCategory;
    };

    const hydrateProductCategories = (unhydratedProductCategories: ProductCategory[]): ProductCategory[] => {
        if (productCategories.length > 0) {
            return unhydratedProductCategories.map((unhydratedProductCategory: ProductCategory) =>
                hydrateProductCategory(unhydratedProductCategory)
            );
        }

        return unhydratedProductCategories;
    };

    // useEffect(() => {
    //     fetchProductCategories();
    // }, []);

    useEffect(() => {
        isFetched.current = false;
    }, [dealer]);

    return {
        productCategories,
        setProductCategories,
        fetchProductCategories,
        isFetchingProductCategories: isFetching,
        getProductCategoryName,
        getCampaignProductCategory,
        getCampaignProductCategories,
        hydrateProductCategory,
        hydrateProductCategories,
    };
}
