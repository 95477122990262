import { useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, FormHelperText, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { Card, CardContent, CardHeader } from './CampaignWizardStyles';

import { AdvertiserContext } from '../../../../AdvertiserContext';
import { CampaignWizardStepProps } from './CampaignWizard';
import { Dealer } from '../../../../types/Dealer';
import { ProductCategory } from '../../../../components/ProductCategoryAutocomplete';
import { toCampaignAdGroupProductCategories, toProductCategories } from '../../../../hooks/useProductCategories';
import { useCampaignWizardContext } from '../../../../hooks/useCampaignWizard';
import DealerSelector from '../../../../components/DealerSelector';
import ProductCategoryAutocomplete from '../../../../components/ProductCategoryAutocomplete';
import Column from '../../../../components/Column';

type CampaignSetupAdvertiserCardProps = {} & CampaignWizardStepProps;

export default function CampaignSetupAdvertiserCard(props: CampaignSetupAdvertiserCardProps) {
    const { campaign, onChange } = props;
    const { advertiserContext, setDealer } = useContext(AdvertiserContext);
    const { hasError, isDraftCampaign, isNewCampaign } = useCampaignWizardContext();

    const { $loader, $productCategories } = useCampaignWizardContext();
    const {
        productCategories: selections = [],
        fetchProductCategories: fetchSelections = () => {},
        isFetchingProductCategories: isFetchingSelections = false,
        hydrateProductCategories: hydrateSelections = (selection: ProductCategory[]) => [],
    } = $productCategories || {};

    const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

    const selectDealer = useCallback(
        (dealerId: number | null, dealer: Dealer) => {
            if (dealerId) {
                onChange({
                    dealerId: dealerId,
                    dealer: dealer,
                });
            }
            setDealer(dealer);
        },
        [setDealer, onChange]
    );

    useEffect(() => {
        if (advertiserContext.dealer) {
            onChange({
                dealerId: advertiserContext.dealer.id,
                dealer: advertiserContext.dealer,
            });
        }
    }, [advertiserContext, onChange]);

    useEffect(() => {
        if ($loader) {
            if (isFetchingSelections) {
                $loader.loading('fetchProductCategories');
            } else {
                $loader.loaded('fetchProductCategories');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingSelections]);

    useEffect(() => {
        if (campaign?.adGroups?.length) {
            if (campaign.adGroups[0]?.adGroupProductCategories) {
                let hydratedProductCategories: ProductCategory[] = hydrateSelections(
                    toProductCategories(campaign.adGroups[0].adGroupProductCategories)
                );

                let parentIds: string[] = hydratedProductCategories
                    .map((productCategory: ProductCategory) => productCategory.parentId as string)
                    .filter((id: string) => id != null)
                    .filter((id: string, index: number, self: string[]) => self.indexOf(id) === index);

                parentIds.forEach((parentId: string) => {
                    if (!hydratedProductCategories.find((parent: ProductCategory) => parent.id === parentId)) {
                        const productCategory: ProductCategory | undefined = selections.find(
                            (selection: ProductCategory) => selection.id === parentId
                        );

                        if (productCategory) {
                            hydratedProductCategories.unshift(productCategory);
                        }
                    }
                });

                setProductCategories(hydratedProductCategories);
            }
        } else {
            setProductCategories([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.adGroups, selections]);

    useEffect(() => {
        if (advertiserContext.dealer) {
            if (!productCategories.length) {
                const parentProductCategory: ProductCategory | undefined = selections.find(
                    (object: ProductCategory) => object.id === '355696083878292622'
                );

                if (parentProductCategory) {
                    updateProductCategories([
                        {
                            ...parentProductCategory,
                            children: [],
                        },
                        ...(parentProductCategory.children ?? []),
                    ]);
                }
            }
        }
    }, [advertiserContext.dealer, selections]);

    const updateProductCategories = useCallback(
        (newProductCategories: ProductCategory[]) => {
            setProductCategories(newProductCategories);

            if (onChange) {
                if (campaign?.adGroups?.length) {
                    campaign.adGroups[0] = {
                        ...campaign.adGroups[0],
                        adGroupProductCategories: toCampaignAdGroupProductCategories(
                            newProductCategories,
                            campaign.adGroups[0].adGroupProductCategories ?? []
                        ),
                    };
                } else {
                    campaign.adGroups.push({
                        adGroupProductCategories: toCampaignAdGroupProductCategories(newProductCategories),
                    });
                }

                onChange({
                    adGroups: campaign.adGroups,
                });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [onChange, campaign]
    );

    const handleProductCategoryChange = (categories: ProductCategory[], reason: string): void => {
        updateProductCategories(categories);
    };

    return (
        <Card id="campaign-wizard-setup-advertiser" variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="body1" color="text.primary">
                            Advertiser Info
                        </Typography>
                    </Stack>
                }
            />
            <CardContent>
                <Column>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl
                                sx={{
                                    '&': {
                                        width: '100%',
                                    },
                                    '& .MuiAutocomplete-root': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <DealerSelector
                                    required={true}
                                    label="Advertiser"
                                    showLabel={true}
                                    error={hasError('dealerId')}
                                    disabled={!isNewCampaign || isDraftCampaign}
                                    agencyId={advertiserContext.agencyId}
                                    dealerId={campaign.dealerId}
                                    dealerSelected={selectDealer}
                                    variant="outlined"
                                />

                                {hasError('dealerId') && (
                                    <FormHelperText error={true}>Advertiser is required</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            {campaign.dealer && campaign.dealer.logoUrl && (
                                <Tooltip title={campaign.dealer.url}>
                                    <Paper elevation={4}>
                                        <a
                                            href={campaign.dealer.url}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                            }}
                                            target="new"
                                        >
                                            <img alt="" height="40px" src={campaign.dealer.logoUrl} />
                                        </a>
                                    </Paper>
                                </Tooltip>
                            )}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                sx={{
                                    '&': {
                                        width: '100%',
                                    },
                                    '& .MuiAutocomplete-root': {
                                        width: '100%',
                                    },
                                }}
                            >
                                <ProductCategoryAutocomplete
                                    hideCount={true}
                                    hideDepthOption={true}
                                    error={hasError('adGroups.*.adGroupProductCategories')}
                                    disabled={!campaign.dealer}
                                    disableCloseOnSelect
                                    label="Product Category"
                                    selectedCategories={productCategories.filter(
                                        (productCategory: ProductCategory) => productCategory.name.length > 0
                                    )}
                                    onSelectedCategories={handleProductCategoryChange}
                                    selections={selections}
                                    fetchSelections={fetchSelections}
                                    // filterSelections={(_selections: ProductCategory[]): ProductCategory[] => {
                                    //     return _selections.filter((_selection: ProductCategory) => {
                                    //         return !_selection.parentId;
                                    //     });
                                    // }}
                                />

                                {hasError('adGroups.*.adGroupProductCategories') && (
                                    <FormHelperText error={true}>Product category is required</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Column>
            </CardContent>
        </Card>
    );
}
