import { useCallback, useState } from 'react';
import { ForecastingParameters } from '../types/AdvertiserRequest';
import { useForecastingParameterListQuery } from './Queries/ForecastingParameterListQuery';

export const FORECASTING_STATUS_NARROW = 'narrow';
export const FORECASTING_STATUS_BROAD = 'broad';
export const FORECASTING_STATUS_BALANCED = 'balanced';

export function useForecastingParameters() {
    const { data } = useForecastingParameterListQuery();

    const [forecastingParameters, setForecastingParameters] = useState<ForecastingParameters>(
        new ForecastingParameters()
    );

    const fetchForecastingParameters = useCallback(() => {
        setForecastingParameters(data || new ForecastingParameters());
    }, [data]);

    const identifyForecastingStatus = (availableReach: number): string => {
        if (availableReach < forecastingParameters.reachNarrow) {
            return FORECASTING_STATUS_NARROW;
        }

        if (availableReach > forecastingParameters.reachBroad) {
            return FORECASTING_STATUS_BROAD;
        }

        return FORECASTING_STATUS_BALANCED;
    };

    return {
        fetchForecastingParameters,
        forecastingParameters,
        setForecastingParameters,

        identifyForecastingStatus,
    };
}
