import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Campaign, CpmValue } from '../types/Campaign';
import { useCampaignCPMValueList } from './Models/CampaignCPMValueList';
import { useForecastingParameters } from './useForecastingParameters';

export const CAMPAIGN_AUDIENCE_MAX_IMPRESSIONS = 6000000;

export type CampaignAudienceProps = {
    campaign: Campaign;
    onChange(budget: number, impressionGoal: number | null, impressionBased: boolean): void;
};

export function useCampaignAudience(props: CampaignAudienceProps) {
    const { campaign, onChange } = props;

    const { getCpmValue } = useCampaignCPMValueList({ dealerId: campaign.dealerId || 0 });
    const cpmValue = useMemo((): CpmValue => {
        return getCpmValue(campaign.mediaType as string);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.mediaType]);

    const $forecastingParameters = useForecastingParameters();
    const { fetchForecastingParameters } = $forecastingParameters;
    const { forecastingParameters, setForecastingParameters } = $forecastingParameters;
    const { identifyForecastingStatus } = $forecastingParameters;

    const [maxImpressions, setMaxImpressions] = useState<number>(0);
    const [impressionBased, setImpressionBased] = useState<boolean>(
        campaign.impressionGoal ? true : campaign.impressionBased
    );

    const forecastingStatus: string = identifyForecastingStatus(campaign.availableReach);

    useEffect(() => {
        fetchForecastingParameters();
    }, [fetchForecastingParameters]);

    useEffect(() => {
        let maxImpressions = CAMPAIGN_AUDIENCE_MAX_IMPRESSIONS;
        if (campaign.availableImpressions < maxImpressions) {
            maxImpressions = campaign.availableImpressions;
        }
        setMaxImpressions(maxImpressions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.availableImpressions]);

    useEffect(() => {
        const impressionGoal: number = campaign.impressionGoal as number;
        const budget: number = calculateBudgetByImpression(impressionGoal);
        const impressionBased: boolean = campaign.impressionBased;

        if (onChange) {
            if (impressionBased) {
                onChange(budget, impressionGoal, impressionBased);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpmValue]);

    useEffect(() => {
        if (campaign.impressionGoal) {
            setImpressionBased(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.impressionGoal]);

    function getCPM(): number {
        return cpmValue ? cpmValue.cpmValue : 0;
    }

    const calculateBudgetByImpression = (impressionGoal: number): number => {
        let budget: number = 0;

        if (isNaN(impressionGoal)) {
            impressionGoal = 0;
        }

        budget = (getCPM() * impressionGoal) / 1000;
        budget = parseInt(budget.toFixed(0));

        return budget;
    };

    const handleChangeImpression = (event: ChangeEvent<HTMLInputElement>): void => {
        const impressionGoal: number = parseInt(event.target.value);
        const budget: number = calculateBudgetByImpression(impressionGoal);
        const impressionBased: boolean = true;

        if (onChange) {
            onChange(budget, impressionGoal, impressionBased);
        }
    };

    return {
        forecastingStatus,
        fetchForecastingParameters,
        forecastingParameters,
        setForecastingParameters,

        maxImpressions,
        setMaxImpressions,
        impressionBased,
        setImpressionBased,

        calculateBudgetByImpression,
        handleChangeImpression,
    };
}
