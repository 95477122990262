import { useQueryClient } from 'react-query';

const fetchData = async (baseUrl: string, path: string) => {
    // Remove trailing slash from baseUrl if it exists
    baseUrl = baseUrl.replace(/\/$/, '');

    // Remove leading slash from path if it exists
    path = path.replace(/^\//, '');

    const response = await fetch(`${baseUrl}/${path}.json`);
    if (!response.ok) {
        console.log('response', response);
        throw new Error('Failed to fetch data from resource.');
    }
    return await response.json();
};

interface UseMapBoundaryDataProps {}

const useMapBoundaryData = (props?: UseMapBoundaryDataProps) => {
    const queryClient = useQueryClient();

    const fetchMapBoundaryData = async (baseUrl: string, path: string) => {
        const cachedData = queryClient.getQueryData(['geographicTargetingData', baseUrl, path]);

        if (cachedData) {
            return cachedData;
        }

        const data = await fetchData(baseUrl, path);

        queryClient.setQueryData(['geographicTargetingData', baseUrl, path], data);

        return data;
    };

    return { fetchMapBoundaryData };
};

export { useMapBoundaryData };
