import { useEffect } from 'react';
import { Stepper, StepIcon, StepIconProps, Step as BaseStep, Typography } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

import { Stepper as StepperObject } from '../../../../hooks/useStepper';
import { StepButton, StepContent, StepLabel } from './CampaignWizardStyles';
import CampaignWizardGeographyStepNav from './CampaignWizardGeographyStepNav';
import CampaignWizardSetupStepNav from './CampaignWizardSetupStepNav';

type Step = {
    number: number;
    label: string;
    description: string;
};

const steps: Step[] = [
    {
        number: 0,
        label: 'Campaign Setup',
        description: ``,
    },
    {
        number: 1,
        label: 'Targeting',
        description: '',
    },
    {
        number: 2,
        label: 'Creative',
        description: ``,
    },
    {
        number: 3,
        label: 'Review & Submit',
        description: ``,
    },
];

type CampaignWizardStepNavProps = {
    // step: number;
    nonLinear: boolean;
    stepper: StepperObject;
    onStep?: (step: number) => void;
};

export default function CampaignWizardStepNav(props: CampaignWizardStepNavProps) {
    const { nonLinear = false } = props;
    const { onStep, stepper } = props;
    const { activeStep, toStep, canStep } = stepper;

    useEffect(() => {
        if (onStep) {
            onStep(activeStep);
        }
    }, [activeStep, onStep]);

    const handleStep = (step: Step): void => {
        toStep(step.number);
    };

    return (
        <Stepper nonLinear={nonLinear} activeStep={activeStep} connector={<></>} orientation="vertical">
            {steps.map((step: Step, index) => (
                <BaseStep key={step.label} completed={activeStep > index} disabled={!canStep(step.number)}>
                    <StepButton
                        onClick={() => handleStep(step)}
                        sx={{
                            boxSizing: 'inherit',
                            margin: 0,
                            py: 0,
                        }}
                    >
                        <StepLabel
                            StepIconComponent={(props: StepIconProps) => {
                                const { error } = props;

                                if (error) {
                                    return <ErrorIcon color="error" />;
                                }

                                return <StepIcon {...props} />;
                            }}
                            error={stepper.hasErrorStep(step.number)}
                        >
                            {step.label}
                        </StepLabel>
                    </StepButton>
                    <StepContent>
                        <Typography>{step.description}</Typography>

                        {index === 0 && <CampaignWizardSetupStepNav />}

                        {index === 1 && <CampaignWizardGeographyStepNav />}
                    </StepContent>
                </BaseStep>
            ))}
        </Stepper>
    );
}
