import { Button, MenuItem } from '@mui/material';
import { LibraryAdd as LibraryAddIcon } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme, { navBackground } from '../../theme';
import AccessGuard from '../AccessGuard';
import {
    AGENCY_ADVERTISERS,
    AGENCY_CAMPAIGNS,
    USER_GROUP_ADMIN,
    USER_GROUP_ADVERTISER,
    USER_GROUP_AGENCY,
} from '../../types/User';

export default function CreateButton() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (link: string) => {
        setAnchorEl(null);
        if (link !== '') {
            navigate(link);
        }
    };

    return (
        <AccessGuard
            accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
            accessAgency={`${AGENCY_CAMPAIGNS},${AGENCY_ADVERTISERS}`}
        >
            <>
                <Button
                    size="medium"
                    sx={{
                        color: '#ffffff',
                        background: 'linear-gradient(to right bottom,#52E5E7, #130CB7)',
                        fontSize: '12px',
                        height: '40px',
                        px: '6px',
                        py: '5px',
                        '& .MuiButton-startIcon': {
                            ml: '0',
                            mr: '4px',
                        },
                    }}
                    startIcon={<LibraryAddIcon fontSize="medium" />}
                    variant="contained"
                    onClick={handleClick}
                >
                    Create
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose('')}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        zIndex: 1600,
                        '& .MuiMenu-paper': {
                            color: theme.palette.primary.contrastText,
                            backgroundColor: navBackground,
                            borderWidth: '1px',
                            borderColor: theme.palette.action.selected,
                            borderStyle: 'solid',
                            minWidth: '176px',
                        },
                    }}
                >
                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                        accessAgency={AGENCY_CAMPAIGNS}
                    >
                        <MenuItem onClick={() => handleClose('/campaign/create')}>Campaign</MenuItem>
                    </AccessGuard>
                    <AccessGuard accessGroup={`${USER_GROUP_ADMIN}`}>
                        <MenuItem onClick={() => handleClose('/campaign/v2/create')}>Campaign 2.0</MenuItem>
                    </AccessGuard>
                    <AccessGuard
                        accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_ADVERTISER},${USER_GROUP_AGENCY}`}
                        accessAgency={AGENCY_ADVERTISERS}
                    >
                        <MenuItem onClick={() => handleClose('/advertisers/new')}>Advertiser</MenuItem>
                    </AccessGuard>
                </Menu>
            </>
        </AccessGuard>
    );
}
