import { Campaign } from '../types/Campaign';

interface UseState {
    isCampaignGatewayUsed: () => boolean;
}

const useCampaignGateway = (campaign: Campaign): UseState => {
    const isCampaignGatewayUsed = (): boolean => {
        return campaign.version !== undefined && campaign.version < 2;
    };

    return {
        isCampaignGatewayUsed,
    };
};

export type { UseState as UseCampaignGatewayState };
export { useCampaignGateway };
