import { useCallback, useEffect, useRef, useState } from 'react';

import { Location } from '../../types/Location';
import { ZipCode } from '../Map/RadialMap';
import { useMapToken } from '../../hooks/Models/MapToken';
import Map from '../Map/RadialMap';

export type { ZipCode };

const boundaryStyles = {
    'background-color': '#1fbdf6',
    opacity: 0.5,
    'line-color': '#0004ae',
    'line-opacity': 0.6,
    selected: {
        'background-color': 'yellow',
        'line-color': '#0004ae',
        'line-opacity': 0.6,
    },
};

export default function LocationMap(props: {
    locations: Location[];
    onChangeLocations?: (locations: Location[]) => void;

    zipCode: string;
    zipCodes: string;
    radius: number;
    onZipsLoaded(results: ZipCode[]): void;
    onZipClick(zip: ZipCode): void;
    reuseMaps?: boolean;
}) {
    const { onChangeLocations, onZipsLoaded, onZipClick, locations, radius, zipCode } = props;
    const { mapToken: token } = useMapToken();

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const $locations = useRef<Location[]>([]);

    const getLocationCenter = useCallback(
        async (_zipCode: string) => {
            if (!token) {
                return [];
            }

            const response = await fetch(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${_zipCode}.json?limit=1&proximity=ip&access_token=${token}`
            );

            const data = await response.json();

            if (data?.features?.length) {
                return data.features[0].center;
            }

            return [];
        },
        [token]
    );

    const getCenterPointByGeocoder = useCallback(async () => {
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${props.zipCode}.json?limit=1&proximity=ip&access_token=${token}`
        );

        const data = await response.json();

        if (data.features.length > 0) {
            return { center: data.features[0].center };
        }

        // USA
        return { center: [-97.9222112121185, 39.3812661305678] };
    }, [props.zipCode, token]);

    const resolveLocations = useCallback(async () => {
        if (locations.length > 0) {
            const results = await Promise.all(
                locations.map(async (location: Location) => {
                    if (!location?.longitude && !location?.latitude && location.zipCode) {
                        const [longitude, latitude] = await getLocationCenter(location.zipCode);
                        return { ...location, longitude, latitude, radius };
                    }

                    return { ...location, radius };
                })
            );

            $locations.current = results;
        } else {
            $locations.current = [];
        }

        setIsDirty(true);
    }, [locations, getLocationCenter, radius]);

    useEffect(() => {
        if (isDirty) {
            if (onChangeLocations) {
                onChangeLocations($locations.current);
            }

            setIsDirty(false);
        }
    }, [isDirty, onChangeLocations]);

    useEffect(() => {
        resolveLocations();
    }, [resolveLocations]);

    return (
        <Map
            reuseMaps={props.reuseMaps}
            baseUrl="/api/geographicTargeting"
            locations={$locations.current}
            onZipClick={onZipClick}
            onZipsLoaded={onZipsLoaded}
            code={zipCode}
            zipCodes={props.zipCodes ?? ''}
            radius={radius}
            displayBoundariesOnStart={false}
            mapboxAccessToken={token || ''}
            getCenterPoint={getCenterPointByGeocoder}
            boundaryStyles={boundaryStyles}
        />
    );
}
