import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async (codeGroup: string) => {
    const { data } = await ApiService.getCodes(codeGroup);
    return data;
};

const useCodeListQuery = (codeGroup: string) => {
    return useQuery(['codeList', codeGroup], () => fetchData(codeGroup), {
        staleTime: Infinity,
        enabled: !!codeGroup,
    });
};

export { useCodeListQuery };
