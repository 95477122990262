import { useCallback, useEffect, useState } from 'react';

import { Campaign, CampaignAdGroupAudienceSource } from '../types/Campaign';
import { Dealer } from '../types/Dealer';
import { AudienceSource, AudienceSourceFee, AudienceSourceInventoryForecast } from '../types/AudienceSource';
import { DspAudience, DspAudienceFee } from '../types/DspAudience';
import { useDspAudienceSourceListQuery } from '../hooks/Queries/DspAudienceSourceListQuery';

export function toCampaignAdGroupAudienceSources(
    audienceSources: AudienceSource[],
    adGroupAudienceSources: CampaignAdGroupAudienceSource[] = []
): CampaignAdGroupAudienceSource[] {
    return [
        ...adGroupAudienceSources.filter((x: CampaignAdGroupAudienceSource) => {
            return audienceSources.find((y: AudienceSource) => x.dspAudienceId === y.audienceId) !== undefined;
        }),
        ...audienceSources
            .filter((x: AudienceSource) => {
                return (
                    adGroupAudienceSources.find(
                        (y: CampaignAdGroupAudienceSource) => x.audienceId === y.dspAudienceId
                    ) === undefined
                );
            })
            .map((audienceSource: AudienceSource) => {
                return {
                    dspAudienceId: audienceSource.audienceId,
                };
            }),
    ];
}

export function toAudienceSources(adGroupAudienceSources: CampaignAdGroupAudienceSource[]): AudienceSource[] {
    let audienceSources: AudienceSource[] = [];

    if (adGroupAudienceSources.length > 0) {
        audienceSources.push(
            ...adGroupAudienceSources.map((adGroupAudienceSource: CampaignAdGroupAudienceSource) => {
                return {
                    audienceId: adGroupAudienceSource.dspAudienceId,
                    audienceName: '',
                    description: '',
                    category: '',
                    subCategory: '',
                    status: '',
                    providerId: '',
                    forecasts: {},
                    fees: [],
                };
            })
        );
    }

    return audienceSources;
}

export function getAudienceSourceInventoryForecast(
    audienceSource: AudienceSource,
    target: string,
    metric: string
): AudienceSourceInventoryForecast {
    let forecast: AudienceSourceInventoryForecast = {
        lowerBoundInclusive: 0,
        upperBoundExclusive: 0,
    };

    if (audienceSource?.forecasts?.inventoryForecasts?.[target]?.[metric]?.lowerBoundInclusive) {
        forecast.lowerBoundInclusive = audienceSource.forecasts.inventoryForecasts[target][metric].lowerBoundInclusive;
    }

    if (audienceSource?.forecasts?.inventoryForecasts?.[target]?.[metric]?.upperBoundExclusive) {
        forecast.upperBoundExclusive = audienceSource.forecasts.inventoryForecasts[target][metric].upperBoundExclusive;
    }

    return forecast;
}

export function toDspAudienceFeeDTO(dspAudienceFee: DspAudienceFee): AudienceSourceFee {
    const dspAudienceFeeDTO: AudienceSourceFee = {
        amount: dspAudienceFee.amount,
        currency: dspAudienceFee.currency,
        feeCalculationType: dspAudienceFee.feeCalculationType,
        impressionSupplyType: dspAudienceFee.impressionSupplyType,
        scale: dspAudienceFee.scale,
    };

    return dspAudienceFeeDTO;
}

export function toDspAudienceDTO(dspAudience: DspAudience): AudienceSource {
    const dspAudienceDTO: AudienceSource = {
        audienceId: dspAudience.audienceId,
        audienceName: dspAudience.audienceName,
        description: dspAudience.description,
        category: dspAudience.category,
        subCategory: dspAudience.subCategory,
        status: dspAudience.status,
        providerId: dspAudience.providerId,
        forecasts: {
            inventoryForecasts: {
                all: {
                    dailyReach: {
                        lowerBoundInclusive: dspAudience.dailyReachLowerBoundInclusive,
                        upperBoundExclusive: dspAudience.dailyReachUpperBoundExclusive,
                    },
                    dailyImpressions: {
                        lowerBoundInclusive: dspAudience.dailyImpressionsLowerBoundInclusive,
                        upperBoundExclusive: dspAudience.dailyImpressionsUpperBoundExclusive,
                    },
                },
            },
        },
        fees: dspAudience.fees.length
            ? dspAudience.fees.map((model: DspAudienceFee) => toDspAudienceFeeDTO(model))
            : [],
    };

    return dspAudienceDTO;
}

export interface AudienceSources {
    //
    audienceSources: AudienceSource[];
    setAudienceSources(audienceSources: AudienceSource[]): void;
    fetchAudienceSources(): void;
    isFetchingAudienceSources: boolean;

    //
    getAudienceSourceName(audienceSource: AudienceSource): string;
    getCampaignAudienceSources(campaign: Campaign): AudienceSource[];

    //
    hydrateAudienceSource(unhydratedAudienceSource: AudienceSource): AudienceSource;
    hydrateAudienceSources(unhydratedAudienceSources: AudienceSource[]): AudienceSource[];
}

type useAudienceProps = {
    dealer: Dealer | null | undefined;
};

export default function useAudienceSources(props: useAudienceProps): AudienceSources {
    const { dealer } = props;
    const [audienceSources, setAudienceSources] = useState<AudienceSource[]>([]);

    const { data, refetch, isFetching } = useDspAudienceSourceListQuery();

    const fetchAudienceSources = useCallback(() => {
        if (!data) {
            refetch();
        }
    }, [dealer, data, refetch]);

    useEffect(() => {
        setAudienceSources(data ? data.map((model: DspAudience) => toDspAudienceDTO(model)) : []);
    }, [data]);

    const getAudienceSourceName = (audienceSource: AudienceSource): string => {
        return hydrateAudienceSource(audienceSource)?.audienceName;
    };

    const hydrateAudienceSource = (unhydratedAudienceSource: AudienceSource): AudienceSource => {
        if (audienceSources.length > 0) {
            const hydratedAudienceSource = audienceSources.find(
                (o: AudienceSource) => o.audienceId === unhydratedAudienceSource.audienceId
            );

            if (hydratedAudienceSource) {
                return hydratedAudienceSource;
            }
        }

        return unhydratedAudienceSource;
    };

    const hydrateAudienceSources = (unhydratedAudienceSources: AudienceSource[]): AudienceSource[] => {
        if (audienceSources.length > 0) {
            return unhydratedAudienceSources.map((unhydratedAudienceSource: AudienceSource) =>
                hydrateAudienceSource(unhydratedAudienceSource)
            );
        }

        return unhydratedAudienceSources;
    };

    const getCampaignAudienceSources = (campaign: Campaign): AudienceSource[] => {
        let hydratedAudienceSources: AudienceSource[] = [];

        if (campaign.adGroups.length > 0) {
            if (campaign.adGroups[0]?.adGroupAudienceSources) {
                hydratedAudienceSources = hydrateAudienceSources(
                    toAudienceSources(campaign.adGroups[0].adGroupAudienceSources)
                );
            }
        }

        return hydratedAudienceSources;
    };

    return {
        audienceSources,
        setAudienceSources,
        fetchAudienceSources,
        isFetchingAudienceSources: isFetching,
        getAudienceSourceName,
        getCampaignAudienceSources,
        hydrateAudienceSource,
        hydrateAudienceSources,
    };
}
