import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async () => {
    const { data } = await ApiService.getDspInventorySourceExchanges();
    return data;
};

const useDspInventorySourceExchangeListQuery = () => {
    return useQuery(['dspInventorySourceExchangeList'], () => fetchData(), {
        staleTime: Infinity,
        enabled: false,
    });
};

export { useDspInventorySourceExchangeListQuery };
