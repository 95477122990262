import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import CampaignStatusComponent from './CampaignStatusComponent';
import Utils from '../../components/Utils';
import { UserContext } from '../../App';
import { useContext, useEffect, useState } from 'react';
import CDGrid from '../../components/CDGrid';
import { Campaign, CampaignCriteria } from '../../types/Campaign';
import theme from '../../theme';
import { PublishedWithChangesOutlined } from '@mui/icons-material';
import Row from '../../components/Row';
import { gridClasses } from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import CampaignType from '../../components/CampaignType';

import CampaignManagerGridItemActions from './CampaignManagerGridItemActions';

function renderPercentage(params: GridRenderCellParams) {
    let pct = params.value;
    if (pct === null || pct === undefined) {
        return null;
    }

    let color = theme.palette.text.primary;
    pct = Utils.round(pct * 100, 0);

    if (pct <= 90 || pct >= 106) {
        color = theme.palette.error.main;
    } else if (91 <= pct && pct <= 97) {
        color = theme.palette.warning.main;
    } else if (98 <= pct && pct <= 105) {
        color = theme.palette.success.main;
    }

    return (
        <span
            style={{
                fontWeight: 600,
                color: color,
            }}
        >
            {pct + '%'}
        </span>
    );
}

export default function CampaignManagerGrid(props: {
    campaigns: Campaign[];
    totalCampaigns: number;
    currentPage: number;
    campaignCriteria: CampaignCriteria;
    deliveryStatusId: string;
    onPageChange(page: number): void;
    onSortModelChange(sortModel: any): void;
    onShowDetails(campaign: Campaign): void;
    onGridColumnVisibilityChange(gridColumnVisibility: GridColumnVisibilityModel): void;
}) {
    const {
        campaigns,
        totalCampaigns,
        currentPage,
        onPageChange,
        onSortModelChange,
        onGridColumnVisibilityChange,
        deliveryStatusId,
    } = props;
    const { userContext } = useContext(UserContext);
    const [gridColumnVisibility, setGridColumnVisibility] = useState<GridColumnVisibilityModel>({});

    useEffect(() => {
        onGridColumnVisibilityChange(gridColumnVisibility);
    }, [onGridColumnVisibilityChange, gridColumnVisibility]);

    function cellRenderer_AdvertiserCampaign(params: GridRenderCellParams) {
        return (
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                <span
                    style={{
                        fontWeight: '600',
                    }}
                >
                    <span
                        style={{
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        {params.row.dealer.dealerName}
                    </span>
                </span>
                <br />
                <Row spacing={0} gap={1}>
                    {params.row.hasUpdates && (
                        <Tooltip title="Pending Change Request">
                            <PublishedWithChangesOutlined color="warning" fontSize="small" />
                        </Tooltip>
                    )}
                    {(userContext.isAdvertiser() || params.row.deliverStatusId === 'PENDING_COGNITION') && (
                        <>{params.row.mediaType + ' ' + params.row.campaignType + ' ' + params.row.strategyType}</>
                    )}
                    {userContext.isAdmin() && (
                        <>
                            {params.row.dspCampaignId ? (
                                <Link
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    onClick={(event) => event.stopPropagation()}
                                    href={
                                        'https://advertising.amazon.com/dsp/ENTITY' +
                                        params.row.dspEntity.entityId.substring(1) +
                                        '/orders/' +
                                        params.row.dspCampaignId +
                                        '/edit'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {params.row.campaignName}
                                </Link>
                            ) : (
                                <Typography variant="body2">{params.row.campaignName}</Typography>
                            )}
                            <CampaignType
                                iconOnly
                                small
                                mediaType={params.row.mediaType}
                                campaignType={params.row.campaignType}
                                strategyType={params.row.strategyType}
                            />
                        </>
                    )}
                    {userContext.isAgency() && params.row.dspCampaignId && <>{params.row.campaignName}</>}
                </Row>
            </div>
        );
    }

    useEffect(() => {
        let gridColumnVisibility: GridColumnVisibilityModel;
        // let columnsVisibility = localStorage.getItem('cmGridColumns_' + deliveryStatusId);
        let columnsVisibility = localStorage.getItem('cmGridColumns');
        if (columnsVisibility) {
            gridColumnVisibility = JSON.parse(columnsVisibility) as GridColumnVisibilityModel;
        } else {
            gridColumnVisibility = {
                agency: userContext.isAdmin() && deliveryStatusId === 'PENDING_COGNITION',
                createdByUser: deliveryStatusId === 'PENDING_COGNITION',
                createdDate: deliveryStatusId === 'PENDING_COGNITION',
                impressionGoal: false, // deliveryStatusId !== 'PENDING_COGNITION',
                impressionPacing: false, // deliveryStatusId !== 'PENDING_COGNITION',
                totalImpressions: deliveryStatusId !== 'PENDING_COGNITION',
                totalSpend: deliveryStatusId !== 'PENDING_COGNITION',
                budgetPacing: false, // deliveryStatusId !== 'PENDING_COGNITION',
            };
        }
        setGridColumnVisibility(gridColumnVisibility);
    }, [deliveryStatusId, userContext]);

    function renderImage(size: string, params: GridRenderCellParams) {
        let img = '';

        switch (params.value.toUpperCase()) {
            case 'AMAZON':
                img = `/${size}_Amazon.png`;
                break;

            case 'ROKU':
                img = `/${size}_Roku.png`;
                break;

            case 'SAMSUNG':
                img = `/${size}_Samsung.png`;
                break;
        }

        return <img alt={params.value} src={img} width={size === 'Big' ? '50px' : '40px'} />;
    }

    const columns: GridColumns = [
        {
            headerName: 'Platform',
            field: 'platform',
            width: 80,
            renderCell: (params) => {
                return renderImage('Small', params);
            },
        },
        {
            headerName: 'Agency',
            field: 'agency',
            minWidth: 130,
            flex: 1,
            valueGetter: (params) => {
                return params.row.dealer.agency.name;
            },
        },
        {
            headerName: 'User',
            field: 'createdByUser',
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => {
                return params.value.name;
            },
        },
        {
            field: 'createdDate',
            headerName: 'Create Date',
            minWidth: 130,
            flex: 1,
            valueGetter: (params) => Utils.getDate(params.value),
            renderCell: (params) => {
                return Utils.formatESTDateLong(params.value);
            },
        },
        {
            headerName: 'Advertiser / Campaign',
            field: 'dealerName',
            minWidth: 200,
            flex: 2,
            renderCell: cellRenderer_AdvertiserCampaign,
            disableColumnMenu: true,
        },
        {
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            field: 'deliveryStatus',
            minWidth: deliveryStatusId === '' || deliveryStatusId === 'LINEITEMS_NOT_RUNNING' ? 170 : 100,
            flex: 1,
            valueGetter: (params) => {
                return params.value.internalStatus;
            },
            renderCell: (params) => {
                return <CampaignStatusComponent deliveryStatus={params.row.deliveryStatus} />;
            },
            disableColumnMenu: true,
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => Utils.getDate(params.value),
            renderCell: (params) => {
                return (
                    <span
                        style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        {Utils.formatESTDateLong(params.value)}
                    </span>
                );
            },
            valueFormatter: (params) => {
                return Utils.formatESTDateLong(params.value);
            },
            disableColumnMenu: true,
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            headerAlign: 'center',
            align: 'center',
            minWidth: 120,
            flex: 1,
            valueGetter: (params) => Utils.getDate(params.value),
            valueFormatter: (params) => {
                return Utils.formatESTDateLong(params.value);
            },
            renderCell: (params) => {
                return (
                    <span
                        style={{
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        {Utils.formatESTDateLong(params.value)}
                    </span>
                );
            },
            disableColumnMenu: true,
        },
        {
            field: 'budget',
            type: 'number',
            headerName: 'Budget',
            headerAlign: 'right',
            align: 'right',
            minWidth: 90,
            renderCell: (params) => {
                return Utils.formatCurrency(params.value, 0);
            },
            disableColumnMenu: true,
        },
        {
            field: 'impressionGoal',
            type: 'number',
            headerName: 'Impression Goal',
            description: 'Impression Goal',
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            flex: 1,
            renderCell: (params) => {
                return params.value ? params.value.toLocaleString() : '';
            },
            disableColumnMenu: true,
        },
        {
            field: 'totalSpend',
            type: 'number',
            headerName: 'Spend',
            description: 'Spend',
            headerAlign: 'right',
            align: 'right',
            minWidth: 90,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return Utils.formatCurrency(params.value, 2);
            },
        },
        {
            field: 'totalImpressions',
            type: 'number',
            headerName: 'Impressions',
            description: 'Impressions',
            headerAlign: 'right',
            align: 'right',
            minWidth: 110,
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                return params.value === null ? null : params.value.toLocaleString();
            },
        },
        {
            field: 'impressionPacing',
            type: 'number',
            headerName: 'Impression Pacing',
            description: 'Impression Pacing',
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            flex: 1,
            disableColumnMenu: true,
            renderCell: renderPercentage,
        },
        {
            field: 'budgetPacing',
            type: 'number',
            headerName: 'Budget Pacing',
            description: 'Budget Pacing',
            headerAlign: 'right',
            align: 'right',
            minWidth: 100,
            flex: 1,
            disableColumnMenu: true,
            renderCell: renderPercentage,
        },
        {
            flex: 0.5,
            width: 50,
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            renderCell: (params) => {
                return <CampaignManagerGridItemActions campaign={params.row} />;
            },
        },
    ];

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    flexGrow: 1,
                    '& .hasUpdates': {
                        backgroundColor: theme.palette.warning.light,
                    },
                }}
            >
                <CDGrid
                    density="compact"
                    experimentalFeatures={{ columnGrouping: true }}
                    onRowClick={(params) => {
                        props.onShowDetails(params.row);
                    }}
                    page={currentPage}
                    columnVisibilityModel={gridColumnVisibility}
                    onColumnVisibilityModelChange={(params) => {
                        // localStorage.setItem('cmGridColumns_' + deliveryStatusId, JSON.stringify(params));
                        localStorage.setItem('cmGridColumns', JSON.stringify(params));
                        setGridColumnVisibility(params);
                    }}
                    onPageChange={onPageChange}
                    onSortModelChange={onSortModelChange}
                    autoHeight={false}
                    columns={columns}
                    rows={campaigns}
                    rowCount={totalCampaigns}
                    paginationMode="server"
                    sortingMode="server"
                    rowHeight={95}
                    headerHeight={70}
                    sx={{
                        '& .cd--campaign-information': {
                            visibility: 'hidden',
                        },
                        '& .cd--campaign-monthly-information--general': {
                            [`& .${gridClasses.columnHeaderTitle}`]: {
                                visibility: 'hidden',
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
}
