import { useCallback, useEffect, useReducer } from 'react';
import { Code } from '../../types/Code';
import { useCodeListQuery } from '../Queries/CodeListQuery';

const ACTION_TYPES = {
    SET_STATE: 'SET_STATE',
    ASSIGN_DEFAULT_CODE: 'ASSIGN_DEFAULT_CODE',
};

type ActionType = keyof typeof ACTION_TYPES;

interface State {
    codeGroup: string;
    codes: Code[];
    defaultCode: Code | null;
}

interface Action {
    type: ActionType | string;
    payload?: Partial<State>;
}

interface UseState extends State {
    fetchCodes: () => void;
    setCodes: (codes: Code[]) => void;
    getCodeDescription: (code: string | undefined) => string;
    getCode: (code: string | undefined) => Code | null;
    isFetchingCodes: boolean;
    isLoadingCodes: boolean;
}

interface UseStateProps {
    codeGroup: string;
}

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATE:
            return { ...state, ...action.payload };

        case ACTION_TYPES.ASSIGN_DEFAULT_CODE:
            let code: Code | undefined;

            if (state.codes.length > 0) {
                code = state.codes.find((o: Code) => o.defaultOption === true);
            }

            return { ...state, defaultCode: code || null };

        default:
            return state;
    }
};

const initialState: State = {
    codeGroup: '',
    codes: [],
    defaultCode: null,
};

const useCodeList = (props: UseStateProps): UseState => {
    const { codeGroup } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const { data, refetch, isFetching, isLoading } = useCodeListQuery(codeGroup);

    const setCodeGroup = (codeGroup: string): void => {
        dispatch({ type: ACTION_TYPES.SET_STATE, payload: { codeGroup } });
    };

    const setCodes = (codes: Code[]): void => {
        dispatch({ type: ACTION_TYPES.SET_STATE, payload: { codes } });
        dispatch({ type: ACTION_TYPES.ASSIGN_DEFAULT_CODE });
    };

    const getCode = (codeCode: string | undefined): Code | null => {
        let code: Code | undefined;

        if (state.codes && state.codes.length > 0) {
            code = state.codes.find((o: Code) => o.code === codeCode);
        }

        return code || null;
    };

    const getCodeDescription = (codeCode: string | undefined): string => {
        let code: Code | null = getCode(codeCode);

        return code?.description || (codeCode as string);
    };

    const fetchCodes = useCallback((): void => {
        if (!data) {
            refetch();
        }
    }, [data, refetch]);

    useEffect(() => {
        setCodeGroup(codeGroup);
    }, [codeGroup]);

    useEffect(() => {
        setCodes(data || []);
    }, [data]);

    return {
        ...state,

        isFetchingCodes: isFetching,
        isLoadingCodes: isLoading,

        fetchCodes,
        setCodes,
        getCode,
        getCodeDescription,
    };
};

export { ACTION_TYPES as CodeListActionTypes };
export type {
    ActionType as CodeListActionType,
    Action as CodeListAction,
    State as CodeListState,
    UseState as UseCodeListState,
};
export { useCodeList };
